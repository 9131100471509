<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h5 class="sub-title double-line">Staff Management and Coaching</h5>
                        <h2 class="title">Preparing for your success,
                            we provide truly prominent IT solutions
                            On Staff Management and Coaching.</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="capture/coaching.webp" alt="img">
                            </div>
                            <div class="details">
                                <h2>STAFF COACHING</h2>
                                <p style="text-align:justify">
                                    De plus en plus d’entreprises font appel au coaching pour mettre sur pied leur organisation, les accompagner,consolider et optimiser les compétences de leurs équipes ou pour faire face à une problématique donnée. Les managers et dirigeants n’échappent pas à la règle et ont aussi recours à des coachs pour leur apporter des réponses concrètes pour s’affirmer au quotidien ou pour mieux gérer leur solitude du haut du pouvoir.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="capture/coaching1.jpg" alt="img">
                            </div>
                            <div class="details">
                                <h2>WHAT IS COACHING AND HOW DOES IT WORK?</h2>
                                <p style="text-align:justify">
                                    Nowadays, the balance between the managerial and economic data of a company with the individual values of its employees is an essential point for the proper functioning of a society. This is why leaders are increasingly calling on a professional coach. 
                                </p>
                                <p style="text-align:justify">
                                    Coaching is therefore used to provide professional and personalized support to a leader, a team or an entire organization. The objective for the person being coached is to achieve concrete and identifiable results, to consolidate their knowledge and to increase their efficiency.

The coaching of managers and employees is generally useful to provide an answer to two kinds of difficulties: access to a new function or adaptation to change. It uses a wide range of behavioral methods to help the manager achieve his goals. 
                                </p>
                                <p style="text-align:justify">
                                    Coaching is a very effective process that ideally complements the forms of support already in place in the company, such as training and the process of setting up a structure. DAVCA has very competent coaches to accompany you in all these processes. 
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-service-sidebar">
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Catagory</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Accounting</a></li>
                                <li><a href="#">Business Plan</a></li>
                                <li><a href="#">Supply Chain</a></li>
                            </ul>
                        </div> 
                        <div class="widget widget_archive">
                            <h4 class="widget-title">Achivment</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Accounting</a></li>
                                <li><a href="#">Business Plan</a></li>
                                <li><a href="#">Supply Chain</a></li>
                            </ul>
                        </div>
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Download</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#" download>Download PDF</a></li>
                                <li><a href="#" download>Download DOC</a></li>
                            </ul>
                        </div>
                        <!-- <div class="widget widget_catagory">
                            <img src="capture/capture.png" alt="img">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'Coaching'
    }
</script>