import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/i18n'

import Toaster from '@meforma/vue-toaster';

// basics CSS of davca don't remove

// Vendor & Plugins CSS (Please remove the comment from below vendor.min.css & plugins.min.css for better website load performance and remove css files from avobe) -->

// import './assets/css/vendor/vendor.min.css'
// import './assets/css/plugins/plugins.min.css'

// // Main Style CSS 
// import './assets/css/style.css'

import './styles/assets/css/bootstrap.min.css'
import './styles/assets/css/animate.min.css'
import './styles/assets/css/fontawesome.min.css'
import './styles/assets/css/custom-icon.css'
import './styles/assets/css/nice-select.min.css'
import './styles/assets/css/magnific.min.css'
import './styles/assets/css/owl.min.css'
import './styles/assets/css/slick.min.css'
import './styles/assets/css/style.css'
import './styles/assets/css/responsive.css'
import './flaticon/flaticon.css'

// install plugins js
window.$ = window.jQuery = require('jquery')

import Paginate from "vuejs-paginate-next";






createApp(App).use(store).use(router).use(i18n).use(Toaster,{
    // override the global option
    position: "top-right",
  }).mount('#app')

