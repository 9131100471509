<template>
    <div>
        <Breadcrumb pageTitle="Financial and Legal" pageTitles="Financial and Legal" />
        <TaxManagement/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Footer from '@/components/Layouts/Footer'
    import TaxManagement from '@/components/TaxManagement/TaxManagement'
    export default {
        name: 'TaxManagementView',
        components: {
            Breadcrumb,
            Footer,
            TaxManagement
        }
    }
</script>
