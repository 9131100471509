<template>
     <!-- counter area start -->
     <div class="counter-area bg-base pt-5 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="single-exp-inner style-white">
                        <h2><span class="counter">4</span> <sub>Y</sub></h2>
                        <h5 v-html="$t('message.counter.text1')"></h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="single-exp-inner style-white">
                        <h2><span class="counter">10</span></h2>
                        <h5 v-html="$t('message.counter.text2')"></h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="single-exp-inner style-white">
                        <h2><span class="counter">8</span></h2>
                        <h5 v-html="$t('message.counter.text3')"></h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="single-exp-inner style-white">
                        <h2><span class="counter">3</span></h2>
                        <h5 v-html="$t('message.counter.text4')"></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- counter area end -->
</template>

<script>
    export default {
        name: "Counter"
    }
</script>