<template>
    <!-- intro area start -->
    <div class="intro-area mg-top--100 bg-relative">
        <div class="container">
            <div class="row justify-content-center">
<!--                <div class="col-lg-4 col-md-6">-->
<!--                    <div class="single-intro-inner" style="height: 14rem;">-->
<!--                        <div class="thumb media">-->
<!--                            <div class="media-left">-->
<!--                                <i class="icomoon-gear"></i>-->
<!--                            </div>-->
<!--                            <div class="media-body align-self-center">-->
<!--                                <h4>Flexible Solutions</h4>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="details">-->
<!--                            <p>We offer it all for any company needing information technology solutions to help achieve their digital vision.</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-lg-4 col-md-6">-->
<!--                    <div class="single-intro-inner" style="height: 14rem;">-->
<!--                        <div class="thumb media">-->
<!--                            <div class="media-left">-->
<!--                                <i class="icomoon-time"></i>-->
<!--                            </div>-->
<!--                            <div class="media-body align-self-center">-->
<!--                                <h4>Timeliness</h4>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="details">-->
<!--                            <p>It’s no secret that timeliness in customer service is essential to keeping customers satisfied.</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="col-lg-6 col-md-6">
                    <div class="single-intro-inner" style="margin-top: 21px">
                        <div class="thumb media">
                            <div class="media-left">
                                <i class="icomoon-money"></i>
                            </div>
                            <div class="media-body align-self-center">
                                <h4 v-html="$t('message.intro.text1')"></h4>
                            </div>
                        </div>
                        <div class="details">
                            <p v-html="$t('message.intro.text2')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- intro area end -->
</template>

<script>
    export default {
        name: 'Introduction'
    }
</script>
<style  scoped>
    .single-intro-inner {
        box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.22);
        border-radius: 28px;
        padding: 69px 100px 51px 73px;
        display: flex;
        flex-direction: column;
        padding: 25px;
    }
</style>