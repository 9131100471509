<template>
    <div>
       <Breadcrumb pageTitle="PLM Customization" pageTitles="PLM Customization" />
       <PLMCustomization/>
    </div>
</template>

<script>
    import PLMCustomization from '@/components/PLMCustomization/PLMCustomization'
    import Breadcrumb from '@/components/Common/Breadcrumb'
    export default {
        name: 'PLMCustomizationView',
        components: {
            PLMCustomization,
            Breadcrumb
        }
    }
</script>