<template>
    <!-- footer area start -->
    <footer class="footer-area bg-cover" style="background-image: url('assets/img/bg/2.webp');">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_about">
                        <h4 class="widget-title" v-html="$t('message.footer.text1')"></h4>
                        <div class="details">
                            <p v-html="$t('message.footer.text2')"></p>
                            <p>Ngousso Total , BP: 34052 , Yaoundé , CAMEROON.</p>
                            <ul class="social-media">
                                <li>
                                    <a class="facebook" href="javascript:void(0)">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a class="twitter" href="javascript:void(0)">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a class="instagram" href="#">
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a class="youtube" href="#">
                                        <i class="fab fa-youtube"></i>
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_nav_menu">
                        <h4 class="widget-title">{{$t('message.navbar.services')}}</h4>
                        <ul>
                            <li>
                                <a href="/data-migration"> {{$t('message.navbar.MenuItServices.DataMigration')}} </a>
                            </li>
                            <li>
                                <a href="/plm-services">{{$t('message.navbar.MenuItServices.PLMCustomization')}}</a>
                            </li>
<!--                            <li>-->
<!--                                <a href="/plm-customization"> PLM Customization </a>-->
<!--                                <ul>-->
<!--                                    <li>-->
<!--                                        <a href="/windchill"> PTC-WindChill </a>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="/parametrics"> CREO-Parametrics </a>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </li>-->
                            <li>
                                <a href="/bi"> {{$t('message.navbar.MenuItServices.bic')}} </a>
                            </li>
                            <li>
                                <a href="/consultant-it"> {{$t('message.navbar.MenuItServices.bpc')}} </a>
                            </li>
                            <li>
                                <a href="/software"> {{$t('message.navbar.MenuItServices.support')}} </a>
                            </li>
<!--                            <li>-->
<!--                                <a href="/it-risks"> IT Risks and Incidents Management </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="/management-it"> Management and Management of IT-->
<!--                                    Projects </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="/system-audit"> Information Systems Audits </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="/biz-digital"> Business Digitalization </a>-->
<!--                            </li>-->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_nav_menu">
                        <h4 class="widget-title">{{$t('message.navbar.BusinessService')}}</h4>
                        <ul>
                            <li>
                                <a href="/business-development"> {{$t('message.navbar.MenuBsServices.bd')}} </a>
                            </li>
                            <li>
                                <a href="/business-plan"> {{$t('message.navbar.MenuBsServices.bplan')}} </a>
                            </li>
                            <li>
                                <a href="/financial"> {{$t('message.navbar.MenuBsServices.financial')}} </a>
                            </li>
                            <li>
                                <a href="/investors"> {{$t('message.navbar.MenuBsServices.Investors')}} </a>
                            </li>
<!--                            <li>-->
<!--                                <a href="/coaching"> Staff Management and Coaching </a>-->
<!--                            </li>-->
                            <li>
                                <a href="/startegy"> {{$t('message.navbar.MenuBsServices.Strategy')}} </a>
                            </li>
<!--                            <li>-->
<!--                                <a href="/organisation"> Business Management and Organization-->
<!--                                </a>-->
<!--                            </li>-->


                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_subscribe">
                        <h4 class="widget-title" v-html="$t('message.footer.text3')"></h4>
                        <div class="single-input-inner style-border style-bg-none" id="login-newsletter">
                            <input type="text" :placeholder="$t('message.footer.text5')" id="email_newsletter">
                            <button @click="Formnewsletter()"><i class="fa fa-arrow-right"></i></button>
                        </div>
                        <label><input type="checkbox">{{$t('message.footer.text4')}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom text-center">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 align-self-center">
                        <p>© {{ currentYear }}. Davca. <a href="davca-cm.com" v-html="$t('message.footer.text6')"></a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- footer area end -->
</template>
<script>
export default {
  name: "Footer",
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
    methods: {
        Formnewsletter() {
            var info = {};
            var errors = [];
            var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i

            $("#login-newsletter")
            .find("input")
            .each(function() {
                $(this).css("border", "1px solid #ccc");
                if ($(this).val() == "" || $(this).val() == null || !pattern.test($(this).val())) {
                    $(this).css("border", "1px solid #f77575");
                    errors.push($(this).attr("name"));
                }
                info[$(this).attr("name")] = $(this).val();
            });

            if (errors.length > 0) {
                this.$toast.error('Your email address is not good, put another one')
                $("#email_newsletter").focus();
                $("#email_newsletter").select();
            }
            // processNewsletter(info);
        }
    }
};
</script>
