<template>
  <div>
    <Preloader />
    <Search />
    <NavBar/>
  </div>
  <router-view/>
  <Footer />
  <BackToTop/>
</template>

<script>
  import Preloader from '@/components/Layouts/Preloader';
  import Search from '@/components/Layouts/Search';
  import NavBar from '@/components/Layouts/NavBar';
  import BackToTop from '@/components/Layouts/BackToTop';
  import Footer from '@/components/Layouts/Footer';
  import * as external from '@/assets/js/external.js'
  export default {
    name: 'App',
    components: {
        Search,
        Preloader,
        NavBar,
        BackToTop,
        Footer
    },
    mounted() {
      external.head_script('/assets/js/magnific.min.js');
      external.head_script('/assets/js/owl.min.js');
      external.head_script('/assets/js/slick.min.js');
      external.head_script('/assets/js/tweenmax.min.js');
      external.head_script('/assets/js/owl.carousel2.thumbs.min.js');
      external.head_script('/assets/js/waypoint.js');
      external.head_script('/assets/js/counterup.js');
      external.head_script('/assets/js/main.js');
    },
    created() {
      
    },
  }
</script>
