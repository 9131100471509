<template>
    <!-- page title start -->
    <div class="breadcrumb-area bg-black bg-relative">

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-8">
                    <div class="breadcrumb-inner text-center">
                        <h2 class="page-title">{{pageTitle}}</h2>
                        <ul class="page-list">
                            <li><a href="/">Home</a></li>
                            <li class="breadcrumb-item"><a :href="link"> {{pageTitleParent}} </a></li>
                            <li>{{pageTitle}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- page title end -->
</template>

<script>
    export default {
        name: 'BreadcrumbDouble',
        props: {
            pageTitleParent: String,
            pageTitle: String,
            link: String
        }
    }
</script>