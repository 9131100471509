<template>
    <!-- faq area start -->
    <div class="faq-area pd-top-100 pd-bottom-90">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 pe-xl-5 align-self-center">
                    <div class="section-title mb-0">
                        <h2 class="sub-title left-border" v-html="$t('message.faq.text1')"></h2>
                        <h2 class="title" v-html="$t('message.faq.text2')"></h2>
                        <p class="content" style="font-size: 20px" v-html="$t('message.faq.text3')">

                        </p>
                    </div>
                    <div class="accordion mt-4" id="accordionExample">
                        <div class="accordion-item single-accordion-inner style-2">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" v-html="$t('message.faq.text4')">

                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" v-html="$t('message.faq.text5')">

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item single-accordion-inner style-2">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" v-html="$t('message.faq.text6')">

                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" v-html="$t('message.faq.text7')">

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item single-accordion-inner style-2">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" v-html="$t('message.faq.text8')">

                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" v-html="$t('message.faq.text9')">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mt-4 mt-lg-0">
                    <form id="consultation">
                        <div class="consulting-contact-form mx-lg-4">
                            <h2 class="mb-3" v-html="$t('message.faq.text10')"></h2>
                            <div class="single-input-inner style-bg" id="divnom">
                                <input name="nom" type="text" :placeholder="$t('message.faq.text11')" id="nom">
                            </div>
                            <div class="single-input-inner style-bg" id="divemail">
                                <input name="email" type="text" :placeholder="$t('message.faq.text12')" id="email">
                            </div>
                            <div class="single-input-inner style-bg" id="divmessage">
                                <textarea name="message" :placeholder="$t('message.faq.text13')" id="message" maxlength="4"></textarea>
                            </div>
                            <div class="btn-wrap pb-3">
                                <input type="button" class="btn btn-base" @click="sendContact()" :value="$t('message.faq.text14')" />
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
    <!-- faq area end -->
</template>
<script>
    export default {
        name: "Faq",
        methods: {
            sendContact() {
                var info = {};
                var errors = [];
                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                $("#consultation")
                .find("input[type=text]")
                .each(function() {
                    if ($(this).attr("name") == "email" && !pattern.test($(this).val())) {
                        errors.push($(this).attr("name"));
                    }
                    else if ($(this).val() == "" && $(this).attr("name") == "nom") {
                        errors.push($(this).attr("name"));
                    }
                });
                if($("#message").val().length < 5) {
                    errors.push($("#message").attr("name"));
                }
                if (errors.length > 0) {
                    for(var i=0;i<errors.length;i++) {
                        if(errors[i] == "nom") {
                            $("#divnom").css("border", "1px solid #f77575");
                            this.$toast.error("Enter your fullname");
                        }
                        if(errors[i] == "email") {
                            $("#divemail").css("border", "1px solid #f77575");
                            this.$toast.error("Enter your good email address");
                        }
                        if(errors[i] == "message") {
                            $("#divmessage").css("border", "1px solid #f77575");
                            this.$toast.error("Enter your message");
                        }
                    }
                }

                if (errors.length == 0) {
                    alert("teste");
                }
            }
        }
    }
</script>