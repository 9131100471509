<template>
        <!-- project details page start -->
        <div class="project-area pd-top-120 mb-4">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-9">
                        <div class="section-title text-center">
                            <h5 class="sub-title double-line">Our values to Davca</h5>
                            <h2 class="title">Integrity, Performance, Innovation</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="blog-details-page-content">
                            <div class="single-blog-inner">
                                <div class="thumb">
                                    <img src="assets/img/banner/7.webp" alt="img">
                                </div>
                                <div class="details">
                                    <h2>Our Values (Integrity)</h2>
                                    <p>
                                        Righteousness and Honesty are qualities that Perfitcom privileges within its staff. We must inspire confidence by expressing our intentions and by adopting consistent behavior while taking full responsibility for our actions. Every employee is required to behave irreproachably in carrying out his or her mission and act with transparency.
                                    </p>
                                </div>
                                <div class="details">
                                    <h2>Our Values (Performance)</h2>
                                    <p>
                                        We value success and ensure we honour our commitments to our customers and employees. We are convinced that our work must be done in ways that add value to our customers and achieve excellence in everything we do.
                                    </p>
                                </div>
                                <div class="details">
                                    <h2>Our Values (Innovation)</h2>
                                    <p>
                                        We rely on an innovation-driven approach to help our customers think about their current business model, for a better future for their business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-12">
                        <div class="td-service-sidebar">
                            <div class="widget widget_catagory">
                                <h4 class="widget-title">Catagory</h4>                                
                                <ul class="catagory-items">
                                    <li><a href="#">Machine learning</a></li>
                                    <li><a href="#">It management</a></li>
                                    <li><a href="#">IT Consultancy</a></li>
                                </ul>
                            </div> 
                            <div class="widget widget_archive">
                                <h4 class="widget-title">Achivment</h4>                                
                                <ul class="catagory-items">
                                    <li><a href="#">Machine learning</a></li>
                                    <li><a href="#">It management</a></li>
                                    <li><a href="#">IT Consultancy</a></li>
                                </ul>
                            </div>
                            <div class="widget widget_catagory">
                                <h4 class="widget-title">Download</h4>                                
                                <ul class="catagory-items">
                                    <li><a href="#" download>Download PDF</a></li>
                                    <li><a href="#" download>Download DOC</a></li>
                                </ul>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- project details page end -->
</template>

<script>
    export default {
        name: "Values"
    }
</script>