<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Team DAVCA IT-Services</h2>
                        <h3 class="title" style="text-align: left">
                            To deliver business value, it becomes necessary to understand the customer's situation, learn about the problematic issues, and offer a solution that addresses the customer's concerns.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb" style="border-radius: 90px">
                                <img src="assets/img/about/team1_davca.png" alt="img" style="margin-top: -8rem">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/about_davca10.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/about_davca50.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h3 style="color: #0060ff">
                        Achieve the goal faster
                    </h3>
                    <p>
                        Consulting is an effective method for improving the production process, faster implementation of modern technologies and solutions that help optimize the business and support growth. Our role is to support the customer at each stage of implementation of digital transformation and watch over the entire project to ensure that all steps lead to the achievement of jointly set the goal.
                    </p>

                    <h3 style="color: #0060ff;margin-top: 2rem">
                        Educate your team
                    </h3>
                    <p>
                        One important role of consulting is to train and educate the client on innovation. Our consultants are top experts in their fields. They have years of experience, extensive knowledge and practical skills to act as guides in the world of technology and innovation. We try to show our partners what to do and where to look for solutions to achieve their goals.
                    </p>
                </div>
                <h3 style="color: #0060ff;margin-top: 2rem">
                    Check the consultancy offers that meet your expectations
                </h3>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">

                            <div class="details">
                                <h3>Solutions audit</h3>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">
                            <div class="details">
                                <h3>ERP</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">
                            <div class="details">
                                <h3>PLM Consulting Services</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'Erp'
    }
</script>

<style scoped>
    p,ul{
        font-size: 20px;
        text-align: justify;
    }
</style>

