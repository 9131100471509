<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Financial and Tax Management</h2>
                        <h3 class="title" style="text-align: left">Preparing for your success,
                            we provide truly prominent IT solutions
                            On Financial and Tax Management.</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">

                            <div class="details">
                                <h2 class="sstitle">COMPTABILITY</h2>
                                <ul class="square">
                                    <li>Assistance and advice in the processing of accounting transactions</li>
                                    <li>Establishment of financial statements (balance sheets, income statements, TAFIRE)</li>
                                    <li>inventories</li>
                                    <li>Implementation of the chart of accounts specific to your company;</li>
                                    <li>Accounting assistance of any kind…</li>
                                </ul>
                                <p style="text-align:justify">
                                    First conception of the strategy is restrictive insofar as it emphasizes the development of the field of activity. What is embarrassing is that it does not take into account the characteristics that condition the internal qualities of the company. However, it neglects the relationship that the company maintains with its environment. These relationships are essential to the definition of strategy. 
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="details">
                                <h2 class="sstitle">TAXATION</h2>
                                <ul class="square">
                                    <li>Studies and consultations on tax issues;</li>
                                    <li>Follow-up of tax declarations;</li>
                                    <li>Development of the DSF;</li>
                                    <li>Assistance with complaints and verifications from social and tax</li>
                                    <li>administrations (CNPS and TAXES);</li>
                                    <li>Follow-up of CNPS employer and employee files.</li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="details">
                                <h2 class="sstitle">MANAGEMENT CONTROL</h2>
                                <ul class="square">
                                    <li>Preparation and monitoring of the budget;</li>
                                    <li>Internal control ;</li>
                                    <li>Financial management…</li>
                                </ul>
                            </div>
                        </div>
                    </div>



                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-service-sidebar">
                        <div class="thumb">
                            <img src="capture/Pie_Chart.png" alt="img" style="max-width:30rem">
                        </div>
                        <div class="thumb">
                            <img src="capture/taxation.png" alt="img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'TaxManagement'
    }
</script>

<style scoped>
    .disc { list-style-type: disc; } 
    .circle { list-style-type: circle; } 
    .square { list-style-type: square; }
    p, ul {
        font-size: 20px;
        text-align: justify;
    }
    .sstitle {
        color: #0060ff
    }
</style>