<template>
	  <!-- service area start -->
      <div class="service-area pd-top-115 pd-bottom-90" style="background-image: url('assets/img/bg/6.webp');">
        <div class="container">
            <div class="section-title">
                <div class="row">
                    <div class="col-xl-7 col-lg-7 mb-4 mb-lg-0">
                        <h2 class="sub-title left-border">Our Services</h2>
                        <h2 class="title">We provide world class services</h2>
                        <p class="content-strong mt-2" style="font-size: 20px;width: 60rem">
                            Over the years we have gained practical knowledge about business processes, implementation, configuration, data migration and integration of Enterprise class systems.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-layer"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.DataMigration')"></h3>
                            <p v-html="$t('message.services.text6')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.DataMigration')"></h3>
                                <p v-html="$t('message.services.text7')">

                                </p>
                                <a class="btn btn-base btn-small" href="/data-migration" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-application"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.PLMCustomization')"></h3>
                            <p v-html="$t('message.services.text8')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.PLMCustomization')"></h3>
                                <p v-html="$t('message.services.text9')">

                                </p>
                                <a class="btn btn-base btn-small" href="/plm-services" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-cloud-data"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.support')"></h3>
                            <p v-html="$t('message.services.text10')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.support')"></h3>
                                <p v-html="$t('message.services.text11')">

                                </p>
                                <a class="btn btn-base btn-small" href="/software" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-megaphone"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.bic')"></h3>
                            <p v-html="$t('message.services.text12')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.bic')"></h3>
                                <p v-html="$t('message.services.text13')">

                                </p>
                                <a class="btn btn-base btn-small" href="/bi" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-lock"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.bpc')"></h3>
                            <p v-html="$t('message.services.text14')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.bpc')"></h3>
                                <p v-html="$t('message.services.text15')">

                                </p>
                                <a class="btn btn-base btn-small" href="/consultant-it" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                <div class="col-lg-4 col-md-6">-->
                <!--                    <div class="single-service-inner text-center">-->
                <!--                        <div class="icon-box-bg">-->
                <!--                            <i class="icomoon-magnifiying-glass"></i>-->
                <!--                        </div>-->
                <!--                        <div class="details">-->
                <!--                            <h3>Software Development Services</h3>-->
                <!--                            <p>-->
                <!--                                Create Your custom apps with us and grow up with new technologies in IT!-->
                <!--                            </p>-->
                <!--                        </div>-->
                <!--                        <div class="details-hover-wrap">-->
                <!--                            <div class="details-hover">-->
                <!--                                <h3>Software Development Services</h3>-->
                <!--                                <p>-->
                <!--                                    Custom software can be used in any type of company to automate activities and increase the company's competitiveness.-->
                <!--                                    Take the next big step towards growing your business.-->
                <!--                                </p>-->
                <!--                                <a class="btn btn-base btn-small" href="/software">View Details</a>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
            <hr/>
            <div class="section-title">
                <div class="row">
                    <div class="col-xl-10 col-lg-7 mb-4 mb-lg-0">
                        <h2 class="sub-title left-border">Our Business Services</h2>
                        <h2 class="title">We provide world class business services</h2>
                        <p class="content-strong mt-2" style="font-size: 20px;width: 60rem">
                            Over the years we have gained practical knowledge about business processes, implementation, configuration of Enterprise class systems.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-layer"></i>
                        </div>
                        <div class="details">
                            <h3>Business Developement</h3>
                            <p>
                                This service is part of our company to grow your enterprise
                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3>Business Developement</h3>
                                <p>
                                    We provide business development services for startups to help them grow their businesses, increase sales and generate more leads. Our team provides a wide range of business development services tailor for the needs of your startup ranging from expansion support, marketing consulting and sales consulting to setting strategic partnerships and many other services that are designed for enhancing the business performance and addressing the main problems that your startups are facing.
                                </p>
                                <a class="btn btn-base btn-small" href="/investors">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-layer"></i>
                        </div>
                        <div class="details">
                            <h3>Business Plan</h3>
                            <p>
                                The business plan explains the operational and strategic implementation of your business model
                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3>Business Plan</h3>
                                <p>
                                    Realization of the market study, definition of the business model and reflection on the commercial and operational strategy for all companies or individuals
                                </p>
                                <a class="btn btn-base btn-small" href="/coaching">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-layer"></i>
                        </div>
                        <div class="details">
                            <h3>Financial and Legal</h3>
                            <p>
                                Keeping up with the Banking and Finance industry’s challenges is not an easy task, mainly because of the high legal risks involved
                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3>Financial and Legal</h3>
                                <p>
                                    Still, we perceive banking and finance as key areas of our clients’ interest, and we have developed a wide range of legal services for these particular fields
                                </p>
                                <a class="btn btn-base btn-small" href="/strategy">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-layer"></i>
                        </div>
                        <div class="details">
                            <h3>Coaching for Investors in CAMEROON</h3>
                            <p>
                                Whether you are an individual or a company DAVCA offers a wide range of services to meet your needs
                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3>Coaching for Investors in CAMEROON</h3>
                                <p>
                                    To invest in Cameroon, a multitude of choices available to you . We will accompany you to the most appropriate way to your projects ...
                                </p>
                                <a class="btn btn-base btn-small" href="/organisation">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-layer"></i>
                        </div>
                        <div class="details">
                            <h3>Business Strategy</h3>
                            <p>
                                To craft a successful business strategy, it's necessary to obtain a thorough understanding of value creation
                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3>Business Strategy</h3>
                                <p>
                                    DAVCA accompanies you throughout the best practices for the implementation of your strategic business
                                </p>
                                <a class="btn btn-base btn-small" href="/financial">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- service area end -->
</template>

<script>
    export default {
        name: 'AllServices'
    }
</script>

<style scoped>
    .single-service-inner {
        border: 1px solid rgb(217, 217, 217);
        border-radius: 33px;
        background-color: rgb(255, 255, 255);
        transition: all 0.2s ease 0s;
        height: 28rem;
    }
</style>