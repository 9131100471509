<template>
    <div>
        <Breadcrumb pageTitle="Contact" pageTitles="Contact"/>
        <Contact />
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb.vue'
    import Contact from '@/components/Contact/Contact.vue'
    export default {
        name: 'ContactView',
        components: {
            Breadcrumb,
            Contact,
        }
    }
</script>