<template>
     <!-- banner area start -->
     <div class="banner-area banner-area-2 bg-relative" style="background-image: url('assets/img/banner/3.webp');">
        <div class="bg-overlay-gradient"></div>
        <div class="banner-bg-img" style="background-image: url('assets/img/banner/4.webp');"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-9">
                    <div class="banner-inner">
                        <div class="sub-title" style="font-weight: 500;display: flex;align-items: center;font-size: 20px;line-height: 1.3380952381;color:black">
                            <span>DAVCA sarl&nbsp;&nbsp;-&nbsp;&nbsp;{{$t('message.banner.text1')}}</span>
                            <span class="main-slider__slogan-line"></span>
                            <span>{{$t('message.banner.text2')}}</span>
                        </div>
                        <div>
                            <span>Yaoundé – Cameroon</span>
                        </div>
                        <h2 class="title">{{$t('message.banner.text3')}} <span>{{$t('message.banner.text4')}}</span></h2>
                        <p class="content">
                            {{$t('message.banner.text5')}}
                        </p>
                        <p class="content">
                            {{$t('message.banner.text6')}}
                        </p>
                        <div class="thumb text-center" style="padding-bottom: 75px">
                            <img src="assets/img/client/INNEO-Logo.png" alt="img" style="width: 10rem;float: left;">
                            <p style="float: left;margin-left: 15px;font-weight: bolder;font-size: 25px;color: red;">
                                They trusted us
                            </p>
                        </div>
                        <div style="padding-bottom: 2rem">
                            <p class="item-1" v-html="$t('message.banner.text7')"></p>

                            <p class="item-2">{{$t('message.banner.text8')}}<br/>
                                (PTC-WindChill / CREO-Parametrics)</p>

                            <p class="item-3" v-html="$t('message.banner.text9')"></p>

                            <p class="item-4">Business & Project Development</p>
                            <p class="item-5">Coaching for Investors & Personal Development</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- banner area end -->
</template>

<script>

export default {
    name: 'Banner',
}
</script>

<style scoped>
    @media screen and (max-width: 1536px){
        .main-slider__slogan-line {
            height: 32px;
            margin-left: 38px;
            width: 2px;
            background-color: black;
            display: block;
            margin-right: 22px;
        }
    }
    .banner-inner .content {
        color: black;
        text-align: justify;
        font-size: 20px;
        font-weight: 400;
    }
    .item-1,
    .item-2,
    .item-3,
    .item-4,
    .item-5{
        position: absolute;
        display: block;
        /*top: 2em;*/
        width: 150%;
        font-size: 2em;
        color: black;
        font-weight: bold;
        font-style: italic;

        animation-duration: 27s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    .item-1{
        animation-name: anim-1;
    }

    .item-2{
        animation-name: anim-2;
    }

    .item-3{
        animation-name: anim-3;
    }
    .item-4{
        animation-name: anim-4;
    }
    .item-5{
        animation-name: anim-5;
    }

    @keyframes anim-1 {
        0%, 8.3% { left: -100%; opacity: 0; }
        8.3%,10% { left: 25%; opacity: 1; }
        25%, 100% { left: 110%; opacity: 0; }
    }

    @keyframes anim-2 {
        0%, 25% { left: -100%; opacity: 0; }
        35%, 45% { left: 25%; opacity: 1; }
        50%, 100% { left: 110%; opacity: 0; }
    }

    @keyframes anim-3 {
        0%, 50% { left: -100%; opacity: 0; }
        60%, 70% { left: 25%; opacity: 1; }
        70%, 100% { left: 110%; opacity: 0; }
    }
    @keyframes anim-4 {
        0%, 70% { left: -100%; opacity: 0; }
        80%, 90% { left: 25%; opacity: 1; }
        90%, 100% { left: 110%; opacity: 0; }
    }
    @keyframes anim-5 {
        0%, 80% { left: -100%; opacity: 0; }
        90%, 97% { left: 25%; opacity: 1; }
        100% { left: 110%; opacity: 0; }
    }
    @media (max-width: 1599px) {
        .banner-inner .title {
            font-size: 69px;
        }
    }

</style>