<template>
    <div>
         <Breadcrumb pageTitle="Business Strategy" pageTitles="Business Strategy" />
         <Strategy/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Strategy from '@/components/Strategy/Strategy'
    export default {
        name: 'StrategyView',
        components: {
            Breadcrumb,
            Strategy
        }
    }
</script>