<template>
    <div>
        <Banner />
        <AllServices/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import AllServices from '@/components/AllServices/AllServices'
    import Banner from '@/components/Services/Banner';
    export default {
        name: 'AllServicesView',
        components:{
            Breadcrumb,
            AllServices,
            Banner
        }
    }
</script>