<template>
    <!-- navbar start -->
    <!-- <div class="navbar-top style-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 d-lg-inline-block d-none">
                    <div class="logo">
                        <a href=""><img src="logos_davca.png" alt="img" style="max-width:80%"></a>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 align-self-center">
                    <div class="media">
                        <div class="media-left">
                            <i class="fas fa-phone-volume"></i>
                        </div>
                        <div class="media-body">
                            <h6>Office phone number</h6>
                            <p>+237 656 68 71 70 / +491628520252</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-5 align-self-center">
                    <div class="media">
                        <div class="media-left">
                            <i class="far fa-envelope"></i>
                        </div>
                        <div class="media-body">
                            <h6>Email Address</h6>
                            <p>info@davca.fr </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 d-lg-block d-none align-self-center">
                    <div class="social-media-light text-md-end text-center">
                        <a href="#"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
                        <a href="#"><i class="fab fa-twitter" aria-hidden="true"></i></a>
                       
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <nav class="navbar navbar-area navbar-area-2 navbar-expand-lg">
        <div class="container nav-container">
            <div class="responsive-mobile-menu">
                <button class="menu toggle-btn d-block d-lg-none" data-target="#Iitechie_main_menu"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-left"></span>
                    <span class="icon-right"></span>
                </button>
            </div>
            <!--
            <div class="logo d-inline-block d-lg-none">
                <a href="/"><img src="logos_davca.png" alt="img"></a>
            </div>
             <div class="nav-right-part nav-right-part-mobile">
                <a class="search-bar-btn" href="#">
                    <i class="fa fa-search"></i>
                </a>
            </div> -->
            <div class="logo" style="padding-right:10%">
                <a href="/"><img src="/logos_davca.png" alt="img"></a>
            </div>
            <div class="collapse navbar-collapse" id="Iitechie_main_menu">
                <ul class="navbar-nav menu-open text-lg-start">
<!--                    <li v-bind:class = "[isActive ? 'activeur' : '']">-->
<!--                        <a href="/">{{$t('message.navbar.home')}}</a>-->
<!--                    </li>-->
                    <li v-bind:class = "[isActive2 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive3 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive4 ? 'menu-item-has-children activeur' : 'menu-item-has-children']">
                        <a href="#">{{$t('message.navbar.about_us')}}</a>
                        <ul class="sub-menu">
                            <li v-bind:class = "[isActive2 ? 'activeur' : '']"><a v-bind:class = "[isActive2 ? 'active' : '']" href="/contact">{{$t('message.navbar.MenuCompany.Contact')}}</a></li>
<!--                            <li v-bind:class = "[isActive3 ? 'activeur' : '']"><a v-bind:class = "[isActive3 ? 'active' : '']" href="javascript:void(0)">{{$t('message.navbar.MenuCompany.Blog')}}</a></li>-->
                            <li v-bind:class = "[isActive4 ? 'activeur' : '']"><a v-bind:class = "[isActive4 ? 'active' : '']" href="/about">{{$t('message.navbar.MenuCompany.who')}}</a></li>
                        </ul>
                    </li>
                    <li v-bind:class = "[isActive5 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive6 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive7 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive8 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive9 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive10 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive11 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive12 ? 'menu-item-has-children activeur' : 'menu-item-has-children', isActive13 ? 'menu-item-has-children activeur' : 'menu-item-has-children',isActive14 ? 'menu-item-has-children activeur' : 'menu-item-has-children',isActive15 ? 'menu-item-has-children activeur' : 'menu-item-has-children']">
                        <a href="#">{{$t('message.navbar.services')}}</a>
                        <ul class="sub-menu">
                            <li v-bind:class = "[isActive5 ? 'activeur' : '']">
                                <a v-bind:class = "[isActive5 ? 'active' : '']" href="/data-migration">
                                    {{$t('message.navbar.MenuItServices.DataMigration')}}
                                </a>
                            </li>
                            <li v-bind:class = "[isActive9 ? 'activeur' : '']">
                                <a v-bind:class = "[isActive9 ? 'active' : '']" href="/plm-services">
                                    {{$t('message.navbar.MenuItServices.PLMCustomization')}}
                                </a>
                            </li>
<!--                            <li v-bind:class = "[isActive13 ? 'menu-item-has-children activeur' : 'menu-item-has-children',isActive14 ? 'menu-item-has-children activeur' : 'menu-item-has-children',isActive15 ? 'menu-item-has-children activeur' : 'menu-item-has-children']">-->
<!--                                <a v-bind:class = "[isActive13 ? 'active' : '',isActive14 ? 'active' : '',isActive15 ? 'active' : '']" href="/plm-customization">PLM Customization</a>-->
<!--                                <ul class="sub-menu">-->
<!--                                    <li v-bind:class = "[isActive14 ? 'activeur' : '']"><a v-bind:class = "[isActive14 ? 'active' : '']" href="/windchill">PTC-WindChill</a></li>-->
<!--                                    <li v-bind:class = "[isActive15 ? 'activeur' : '']"><a v-bind:class = "[isActive15 ? 'active' : '']" href="/parametrics">CREO-Parametrics</a></li>-->
<!--                                </ul>-->
<!--                            </li>-->
                            <li v-bind:class = "[isActive12 ? 'activeur' : '']"><a v-bind:class = "[isActive12 ? 'active' : '']" href="/bi">{{$t('message.navbar.MenuItServices.bic')}}</a></li>
                            <li v-bind:class = "[isActive11 ? 'activeur' : '']"><a v-bind:class = "[isActive11 ? 'active' : '']" href="/consultant-it">{{$t('message.navbar.MenuItServices.bpc')}}</a></li>
                            <li v-bind:class = "[isActive8 ? 'activeur' : '']"><a v-bind:class = "[isActive8 ? 'active' : '']" href="/software">{{$t('message.navbar.MenuItServices.support')}}</a></li>
                        </ul>
                    </li>
                    <li class="menu-item-has-children" v-bind:class = "[isActivesTotal ? 'menu-item-has-children activeur' : 'menu-item-has-children']">
                        <a href="#" v-bind:class = "[isActivesTotal ? 'active' : '']">{{$t('message.navbar.BusinessService')}}</a>
                        <ul class="sub-menu">
                            <li v-bind:class = "[isActives3 ? 'activeur' : '']"><a v-bind:class = "[isActives3 ? 'active' : '']" href="/business-development">{{$t('message.navbar.MenuBsServices.bd')}}</a></li>
                            <li v-bind:class = "[isActives5 ? 'activeur' : '']"><a v-bind:class = "[isActives5 ? 'active' : '']" href="/business-plan">{{$t('message.navbar.MenuBsServices.bplan')}}</a></li>
                            <li v-bind:class = "[isActives4 ? 'activeur' : '']"><a v-bind:class = "[isActives4 ? 'active' : '']" href="/financial">{{$t('message.navbar.MenuBsServices.financial')}}</a></li>
                            <li v-bind:class = "[isActives ? 'activeur' : '']"><a v-bind:class = "[isActives ? 'active' : '']" href="/investors">{{$t('message.navbar.MenuBsServices.Investors')}}</a></li>
                            <li v-bind:class = "[isActives2 ? 'activeur' : '']"><a v-bind:class = "[isActives2 ? 'active' : '']" href="/strategy">{{$t('message.navbar.MenuBsServices.Strategy')}}</a></li>
                        </ul>
                    </li>
                    <li v-bind:class = "[isAct ? 'activeur' : '']"><a href="/customers">{{$t('message.navbar.OurCustomers')}}</a></li>
                    <li v-bind:class = "[isActive1 ? 'activeur' : '']"><a href="/careers">{{$t('message.navbar.careers')}}</a></li>
                </ul>
            </div>
            <div class="nav-right-part nav-right-part-desktop align-self-center" style="display: -webkit-inline-box;">
                <div style="margin-top:8%">
                    <a class="search-bar-btn" href="#">
                        <i class="fa fa-search"></i>
                    </a>
                </div>
                

                <!-- language-menu -->
                <div class="language-menu" v-if="language=='en'">
                    <ul style="font-size:14px;width:107%;margin-top:8%">
                        <li>
                            <a href="#" class="" @click="changeLanguageEn('en')">
                                <img class="ls-flag" src="@/assets/images/flags/en.webp" alt="en" title="English" style="width:16%">
                                <span class="wpml-ls-native">English</span>
                            </a>
                
                            <ul class="ls-sub-menu">
                                <li class="">
                                    <a href="#" class="" @click="changeLanguageFr('fr')">
                                        <img class="ls-flag" src="@/assets/images/flags/fr.webp" alt="en" title="Français"><span
                                            class="wpml-ls-native">Français</span>
                                    </a>
                                </li>
<!--                                <li class="">-->
<!--                                    <a href="#" class="" @click="changeLanguageDe('de')">-->
<!--                                        <img class="wpml-ls-flag" src="@/assets/images/flags/de.webp" alt="de" title="Deutsch"><span-->
<!--                                            class="wpml-ls-native">Deutsch</span>-->
<!--                                    </a>-->
<!--                                </li>-->
                
                            </ul>
                
                        </li>
                
                    </ul>
                </div>

                <div class="language-menu" v-if="language=='fr'">
                    <ul style="font-size:14px;width:107%;margin-top:8%">
                        <li>
                
                            <a href="#" class="" @click="changeLanguageFr('fr')">
                                <img class="ls-flag" src="@/assets/images/flags/fr.webp" alt="en" title="Français" style="width:16%"><span
                                    class="wpml-ls-native">Français</span>
                            </a>
                
                            <ul class="ls-sub-menu">
                                <li class="">
                                    <a href="#" class="" @click="changeLanguageEn('en')">
                                        <img class="ls-flag" src="@/assets/images/flags/en.webp" alt="en" title="English">
                                        <span class="wpml-ls-native">English</span>
                                    </a>
                                </li>
<!--                                <li class="">-->
<!--                                    <a href="#" class="" @click="changeLanguageDe('de')">-->
<!--                                        <img class="wpml-ls-flag" src="@/assets/images/flags/de.webp" alt="de" title="Deutsch"><span-->
<!--                                            class="wpml-ls-native">Deutsch</span>-->
<!--                                    </a>-->
<!--                                </li>-->
                
                            </ul>
                
                        </li>
                
                    </ul>
                </div>
<!--                <div class="language-menu" v-if="language=='de'">-->
<!--                    <ul style="font-size:14px;width:107%;margin-top:8%">-->
<!--                        <li>-->

<!--                            <a href="#" class="" @click="changeLanguageDe('de')">-->
<!--                                <img class="wpml-ls-flag" src="@/assets/images/flags/de.webp" alt="de" title="Deutsch" style="width:16%"><span-->
<!--                                    class="wpml-ls-native">Deutsch</span>-->
<!--                            </a>-->

<!--                            <ul class="ls-sub-menu">-->
<!--                                <li class="">-->
<!--                                    <a href="#" class="" @click="changeLanguageEn('en')">-->
<!--                                        <img class="ls-flag" src="@/assets/images/flags/en.webp" alt="en" title="English">-->
<!--                                        <span class="wpml-ls-native">English</span>-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="">-->
<!--                                    <a href="#" class="" @click="changeLanguageFr('fr')">-->
<!--                                        <img class="ls-flag" src="@/assets/images/flags/fr.webp" alt="en" title="Français"><span-->
<!--                                            class="wpml-ls-native">Français</span>-->
<!--                                    </a>-->
<!--                                </li>-->

<!--                            </ul>-->

<!--                        </li>-->

<!--                    </ul>-->
<!--                </div>-->

            </div>
        </div>
    </nav>
    <!-- navbar end -->
</template>

<script>

export default {
    name: 'NavBar',
    data() {
        return {
            language: null,
            isActive: false,
            isActive1: false,
            isActive2: false,
            isActive3: false,
            isActive4: false,
            isActive5: false,
            isActive6: false,
            isActive7: false,
            isActive8: false,
            isActive9: false,
            isActive10: false,
            isActive11: false,
            isActive12: false,
            isActive13: false,

            isActive14: false,
            isActive15: false,


            isActives: false,
            isActives1: false,
            isActivesTotal: false,

            isActives2: false,
            isActives3: false,
            isActives4: false,
            isActives5: false,

            isAct: false,
        };
    },
    mounted() {
    },
    methods: {
        changeLanguageFr(langFR) {
            this.language = langFR;
            localStorage.setItem("lang", this.language);
            window.location.reload();
        },
        changeLanguageEn(langEN) {
            this.language = langEN;
            localStorage.setItem("lang", this.language);
            window.location.reload();
        },
        changeLanguageDe(langDE) {
            this.language = langDE;
            localStorage.setItem("lang", this.language);
            window.location.reload();
        }
    },
    created() {
        var path = window.location.pathname.split('/')[1];
        if(path == "") {
            this.isActive = true;
        }
        if(path == "careers") {
            this.isActive1 = true;
        }
        if(path == "contact") {
            this.isActive2 = true;
        }
        if(path == "blog") {
            this.isActive3 = true;
        }
        if(path == "about") {
            this.isActive4 = true;
        }
        if(path == "data-migration") {
            this.isActive5 = true;
        }
        if(path == "biz-digital") {
            this.isActive6 = true;
        }
        if(path == "system-audit") {
            this.isActive7 = true;
        }
        if(path == "software") {
            this.isActive8 = true;
        }
        if(path == "plm-services") {
            this.isActive9 = true;
        }
        if(path == "Business") {
            this.isActive10 = true;
        }
        if(path == "consultant-it") {
            this.isActive11 = true;
        }
        if(path == "bi") {
            this.isActive12 = true;
        }
        if(path == "plm-customization") {
            this.isActive13 = true;
        }
        if(path == "windchill") {
            this.isActive14 = true;
        }
        if(path == "parametrics") {
            this.isActive15 = true;
        }
        if(path == "investors") {
            this.isActives = true;
        }
        if(path == "coaching") {
            this.isActives1 = true;
        }
        if(path == "strategy") {
            this.isActives2 = true;
        }
        if(path == "business-development") {
            this.isActives3 = true;
        }
        if(path == "financial") {
            this.isActives4 = true;
        }
        if(path == "business-plan") {
            this.isActives5 = true;
        }
        if(path == "investors" || path == "coaching" || path == "strategy" || path == "business-development" || path == "financial" || path == "business-plan") {
            this.isActivesTotal = true;
        }
        if(path=='customers') {
            this.isAct = true;
        }
        if(localStorage.getItem("lang")==null) {
            localStorage.setItem("lang", "en");
        }
        this.language = localStorage.getItem("lang");
    }
}
</script>

<style scoped>
    /*======================
        - language 
    =======================*/
    .language-menu {
        padding-right: 25px;
    }

    .language-menu>ul>li {
        position: relative;
        display: inline-block;
        line-height: 1;
        padding: 0 10px;
    }

    .language-menu>ul>li>a {
        padding: 0 10px;
        font-weight: 500;
        /*color: #333;*/
        color: #fff;
        text-transform: uppercase;
    }

    .language-menu>ul>li>a span {
        margin-left: 10px;
    }


    .language-menu>ul>li ul {
        min-width: 200px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        padding: 0;
        margin: 0;
        list-style-type: none;
        z-index: 102;
        -webkit-box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
        box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
        -webkit-transform: translateY(30px);
        -ms-transform: translateY(30px);
        transform: translateY(30px);
        -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .language-menu>ul>li ul li a {
        font-weight: 500;
        color: #333;
        text-transform: uppercase;
        display: block;
        text-decoration: none;
        padding: 10px;
        border: 0;
        background: #fff;
        color: #222;
        line-height: 1;
        text-transform: uppercase;
    }

    .language-menu>ul>li ul li a span {
        margin-left: 10px;
    }

    .language-menu>ul>li ul li a img {
        width: 24px;
        height: auto;
    }

    .language-menu>ul>li ul li a:hover {
        background: #EEEEEE;
    }

    .language-menu>ul>li:hover ul {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
    }
</style>
