<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">{{$t('message.navbar.MenuItServices.PLMCustomization')}}</h2>
                        <h3 class="title" style="text-align: left">
                            We take care of our clients' software so that nothing distracts them from running their business.
                            Entrust your systems to professionals and stay in control of the services we provide.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="assets/img/about/aboutes.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/about_davcas.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/about_davca50.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <h2 style="color: #0060ff">
                        Check out the IoT and PLM Managed Services offering that meets your needs
                    </h2>
                    <p>
                        Select an offer and accelerate digital transformation in your organization!
                    </p>
                    <h3>
                        Service Level Agreements, support, and maintenance (infrastructure and application)
                    </h3>
                    <p>
                        Are you noticing a seasonal increase in demand for IT professionals? Does your IT system require development, frequent repairs or performance monitoring? Or maybe its maintenance requires non-standard professional skills?
                    </p>
                    <p>
                        In order to meet such needs we have created a special offer of support and software development. The core of this offer involves cooperation with the client in defining the expected results and setting the key guidelines for the project. We support applications maintained both by Transition Technologies PSC and by the customer.
                    </p>
                    <h3>
                        Delivery, installation and configuration of components
                    </h3>
                    <p>
                        We develop, install and configure applications based on the ThingWorx platform. Our services range from building applications specifically for the customer (we use the Pure ThingWorx platform for this purpose) to providing ready-made PTC solutions such as Navigate, Operator Advisor, Asset Advisor, Manufacturing Apps.
                    </p>
                    <p>
                        We also implement new ThingWorx Apps (real-time production performance monitoring, factory fleet monitoring and management, digital technology cards for operators). Depending on customer requirements and needs, we provide components that accelerate digital transformation and business growth.
                    </p>
                    <h3>
                        Single Sign On - installation and configuration of authentication
                    </h3>
                    <p>Single Sign On (SSO) is a method of authentication that is the standard for securing access to applications. As part of this service, we integrate selected applications and create a single solution - this allows access to all authorized resources with a single login. We are responsible for the installation and configuration of individual components such as Ping Federate, Azure AD (or third party software), Windchill, Thingworx Navigate.</p>
                    <p>We also offer our customers support and advice on component selection. We start our cooperation with a workshop, during which we get to know the client's situation, resources and challenges, and then, on this basis, we install, configure and adjust individual components.</p>
                    <h3>Network configuration</h3>
                    <p>We will audit your network configuration, check the level of security, compliance with standards and correctness of operation. We will provide clear guidelines on how to proceed and propose solutions that will improve the functioning and quality of your network. We will make sure that your solutions, platforms and applications are secure and able to communicate with each other. Our audits cover all applications and systems supporting business processes from ERP, CRM to SAP etc.</p>
                    <h3>Windchill Upgrades</h3>
                    <p>As a long-time PTC Partner, we are highly specialized in Windchill related services. Our Upgrade & Migration team specializes in upgrading all available Windchill versions 8, 9, 10, 11. We can upgrade not only Windchill components such as Workgroup Managers (WGM), file servers and visualization servers, but also our own solutions. We also upgrade infrastructure such as operating systems, databases, web servers, client applications, etc.
                    </p>
                    <h3>PLM | WNC Support & Maintenance</h3>
                    <p>
                        Perfect solution for customers who require customized services while maintaining the system to ensure optimal performance. We provide technical support and assistance from designated technical staff experienced in PLM environment:
                    </p>
                    <p>
                        <strong>Corrective maintenance</strong> – we will fix irregularities that arose during the operational phase of the PLM solution (after startup).
                    </p>
                    <p>
                        <strong>Adaptive maintenance</strong> – we will adapt your current solutions to the latest trends in the IT market (PTC R&D) and implement the necessary changes resulting from the development of your IT environment.
                    </p>
                    <p>
                        <strong>Preventive maintenance</strong> –we will audit the software repository and documentation to assess the architecture and code quality. This is a service for all custom components and functionality within the PTC Windchill solution. It is an important part of software development work to ensure future scalability and secure potential upgrades.
                    </p>
                    <p>
                        <strong>Perfective maintenance</strong> – we will ensure continuous upgrades to the solutions we offer. We will be upgrading and modifying the OOTB (out of the box) version of the software based on the feedback collected.
                    </p>
                </div>
            </div>

        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'ITRisks'
    }
</script>

<style scoped>
    p {
        font-size: 20px;
        text-align: justify;
    }
</style>