<template>
    <div>
        <Breadcrumb :pageTitle="$t('message.navbar.MenuItServices.bic')" :pageTitles="$t('message.navbar.MenuItServices.bic')" />
        <BI/>
    </div>
</template>

<script>
    import BI from '@/components/BI/BI'
    import Breadcrumb from '@/components/Common/Breadcrumb'
    export default {
        name: 'ERPView',
        components: {
            BI,
            Breadcrumb
        }
    }
</script>
