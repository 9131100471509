<template>
    <div>
        <Breadcrumb pageTitle="Details Career" pageTitles="Details Career"  />
        <CareerDetails />
    </div>
</template>

<style scoped>
    .separateur {
        margin-top: 2rem
    }
</style>


<script>
    import Breadcrumb from '@/components/Common/Breadcrumb.vue'
    import CareerDetails from '@/components/CareerDetails/CareerDetails.vue'
    export default {
        name: 'CareerDetailsView',
        components: {
        Breadcrumb,
        CareerDetails
}
    }
</script>