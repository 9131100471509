<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h5 class="sub-title double-line">Information Systems Audits</h5>
                        <h2 class="title">Preparing for your success,
                            we provide truly prominent IT solutions
                            On Information Systems Audits</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="capture/audit1.png" alt="img">
                            </div>
                            <div class="details">
                                <h2>Information Systems Audits</h2>
                                <p style="text-align:justify">
                                    The computer audit, the audit of information systems evaluates the risks of a computer environment or an application, for example, wages or billing. These missions are made by choosing, with the client, the business processes to be evaluated, as well as the COBIT processes to be evaluated among the 34 proposed. 
                                </p>
                                <p style="text-align:justify">
                                    Auditing an IT environment may involve assessing IT risks related to physical security, logical security, change management, backup plan, and so on. usually the case – to meet a specific customer request. For instance, appreciate the information and systems availability. The COBIT allows you to search for a process. In the case of the availability: for instance, performances and capacities management and the continuity plan. 
                                </p>
                                <h4 class="pt-4 mb-4">The services we offer in Audit are as follows:</h4>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="media single-choose-inner">
                                            <div class="media-left">
                                                <div class="icon">
                                                    <i class="icomoon-gear"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h4>Step 1</h4>
                                                <p style="text-align:justify">
                                                    Thanks to good project management, you will be able to ensure that the tasks carried out to achieve the various objectives are maintained and respected. 
                                                </p>
                                            </div>
                                        </div>
                                        <div class="media single-choose-inner">
                                            <div class="media-left">
                                                <div class="icon">
                                                    <i class="icomoon-time"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h4>Step 2</h4>
                                                <p style="text-align:justify">In addition, the management qualities will not only avoid risks, but will also make effective and efficient use of the resources available to the working group. </p>
                                            </div>
                                        </div>
                                        <div class="media single-choose-inner">
                                            <div class="media-left">
                                                <div class="icon">
                                                    <i class="icomoon-client"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h4>Step 3</h4>
                                                <p style="text-align:justify">
                                                    In addition, team members can be properly guided, understand their responsibilities and the expected results, and will always be mindful of meeting the schedule of tasks to complete the project on time and the budget allocated. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4>More information</h4>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="accordion mt-2" id="accordionExample">
                                            <div class="accordion-item single-accordion-inner style-2">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Audit
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        A computer audit, audit of information systems does not necessarily concern security. Indeed, it can be used to evaluate strategic or quality aspects of information systems. For example, answer the following question: Do enterprise information systems respond effectively to the needs of business services? The approach is very similar, choose and evaluate the IT processes proposed by the COBIT that best meet the client’s demand and objectives. 
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-4 align-self-center mt-4 mt-lg-0">
                                        <div class="thumb image-hover-animate border-radius-5">
                                            <img src="assets/img/service/01.webp" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-service-sidebar">
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Catagory</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Machine learning</a></li>
                                <li><a href="#">It management</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                            </ul>
                        </div> 
                        <div class="widget widget_archive">
                            <h4 class="widget-title">Achivment</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Machine learning</a></li>
                                <li><a href="#">It management</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                            </ul>
                        </div>
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Download</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#" download>Download PDF</a></li>
                                <li><a href="#" download>Download DOC</a></li>
                            </ul>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'SystemeAudits'
    }
</script>