<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Business Plan</h2>
                        <h3 class="title" style="text-align: left">
                            Preparing for your success,
                            we provide truly prominent IT solutions
                            On Drafting of the Business Plan.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="assets/img/about/b-plan.png" alt="img">
                            </div>
                            <div class="details">
                                <h3 style="color: #0060ff">Content Approach by DAVCA</h3>
                                <p style="text-align:justify">
                                    Ready to set up your business, you have an idea of the sector in which you would like to invest, DAVCA draws up the business plan for you. You will thus have a support to better appreciate the feasibility of your project in order to make the decision to launch on appreciable economic and financial bases and to convince possible financial partners.
                                </p>
                                <h3 style="color: #0060ff">The Feasibility Test</h3>
                                <p>
                                    Think of a feasibility test as a reality check for your business idea. The goal of conducting a feasibility test is to prove to yourself and your team or investors the probability of your product or service being successful within your industry.
                                </p>
                                <p>
                                    A feasibility test should be as low-cost as possible and should revolve around creating a Minimum Viable Product (MVP) or simple proof of concept, which communicates the most simple, basic value propositions of your future product or service.
                                </p>
                                <p>
                                    According to Entrepreneurship For Dummies, by Kathleen Allen, a feasibility test weighs the validity of your business concept by examining four main points:
                                </p>
                                <ul>
                                    <li>The product your firm will offer.</li>
                                    <li>The customer you will target.</li>
                                    <li>Your value proposition.</li>
                                    <li>How you will get the product to its intended users.</li>
                                </ul>
                                <p>
                                    By this stage in your business plan, you should have a firm grasp on what product or service you intend to offer, as well as who you believe will be your primary customer. The final item requires weighing various distribution channels, but, again, should be answerable with a little
                                </p>
                                <h3 style="color: #0060ff">The Value Proposition</h3>
                                <p>
                                    Your value proposition is what makes customers choose you instead of the competition. It's part marketing, part operations, and part strategy; your value proposition is the foundation of your competitive advantage.
                                </p>
                                <p>
                                    On a subconscious level, customers will compare the value proposition of your company against those of your competitors when deciding where to take their business. With that in mind, here are a few things to remember when writing your value proposition:
                                </p>
                                <ul>
                                    <li>Keep it short and uncluttered. Your value proposition explains why customers should buy from you. If you can't sum it up in 10 words or less, chances are you won't be able to execute it, either.</li>
                                    <li>Be precise. Your customers have specific needs; your value proposition should offer targeted solutions</li>
                                    <li>It is about your customer, not you. Your value proposition should discuss only what matters to your customers and the value you can bring to them.</li>
                                    <li>Value comes in numerous forms. Money, time, convenience and superior service are a few of the ways you can help deliver value to your customers.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'BusinessPlan'
    }
</script>

<style scoped>
    p, ul {
        font-size: 20px;
        text-align: justify;
    }
</style>