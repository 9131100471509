<template>
    <div>
        <!--BreadcrumbData/-->
        <Breadcrumb :pageTitle="$t('message.navbar.MenuItServices.DataMigration')" :pageTitles="$t('message.navbar.MenuItServices.DataMigration')"/>
        <DataMigration/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import DataMigration from '@/components/DataMigration/DataMigration'
    export default {
        name: 'DataMigrationView',
        components: {
            DataMigration,
            Breadcrumb
        }
    }
</script>