import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ContactView from '@/views/Pages/ContactView.vue'
import BlogView from '@/views/Pages/BlogView.vue'
import CareersView from '@/views/Pages/CareersView.vue'
import CustomersView from '@/views/Pages/CustomersView.vue'
import AboutView from '@/views/Pages/AboutView.vue'
import DataMigrationView from '@/views/Pages/DataMigrationView.vue'
import ComputerAidedView from '@/views/Pages/ComputerAidedView.vue'
import ERPView from '@/views/Pages/ERPView.vue'
import BIView from '@/views/Pages/BIView.vue'
import ItRisksView from '@/views/Pages/ItRisksView.vue'
import ManagementProjectView from '@/views/Pages/ManagementProjectView.vue'
import SystemAuditsView from '@/views/Pages/SystemAuditsView.vue'
import BizDigitalView from '@/views/Pages/BizDigitalView.vue'
import PLMCustomizationView from '@/views/Pages/PLMCustomizationView.vue'
import WindchillView from '@/views/Pages/WindchillView.vue'
import ParametricsView from '@/views/Pages/ParametricsView.vue'
import InvestorsView from '@/views/Pages/InvestorsView.vue'
import CoachingView from '@/views/Pages/CoachingView.vue'
import StrategyView from '@/views/Pages/StrategyView.vue'
import OrganisationView from '@/views/Pages/OrganisationView.vue'
import TaxManagementView from '@/views/Pages/TaxManagementView.vue'
import BusinessPlanView from '@/views/Pages/BusinessPlanView.vue'
import PageNotFoundView from '@/views/PageNotFound/PageNotFoundView.vue'
import AllServicesView from '@/views/Pages/AllServicesView.vue'
import CareerDetailsView from '@/views/Pages/CareerDetailsView.vue'
import MissionsView from '@/views/Pages/MissionsView.vue'
import VisionsView from '@/views/Pages/VisionsView.vue'
import ValuesView from '@/views/Pages/ValuesView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: lazyLoad('HomeView'),
    meta: {
      title: 'DAVCA - Home'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: lazyLoads('ContactView'),
    meta: {
      title: 'DAVCA - Contact'
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: lazyLoads('BlogView'),
    meta: {
      title: 'DAVCA - Blog'
    }
  },
  {
    path: '/careers',
    name: 'careers',
    component: lazyLoads('CareersView'),
    meta: {
      title: 'DAVCA - Careers'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: lazyLoads('AboutView'),
    meta: {
      title: 'DAVCA - About'
    }
  },
  {
    path: '/customers',
    name: 'customers',
    component: lazyLoads('CustomersView'),
    props: true,
    meta: {
      title: 'DAVCA - Customers'
    }
  },
  {
    path: '/data-migration',
    name: 'data-migration',
    component: lazyLoads('DataMigrationView'),
    props: true,
    meta: {
      title: 'DAVCA - Migration'
    }
  },
  {
    path: '/computer-aided',
    name: 'computer-aided',
    component: lazyLoads('ComputerAidedView'),
    props: true,
    meta: {
      title: 'DAVCA - Computer'
    }
  },
  {
    path: '/consultant-it',
    name: 'consultant-it',
    component: lazyLoads('ERPView'),
    props: true,
    meta: {
      title: 'DAVCA - consultant-it'
    }
  },
  {
    path: '/Business',
    name: 'Business',
    component: lazyLoads('BIView'),
    props: true,
    meta: {
      title: 'DAVCA - BI'
    }
  },
  {
    path: '/plm-services',
    name: 'plm-services',
    component: lazyLoads('ItRisksView'),
    props: true,
    meta: {
      title: 'DAVCA - PLM Services'
    }
  },
  {
    path: '/software',
    name: 'software',
    component: lazyLoads('ManagementProjectView'),
    props: true,
    meta: {
      title: 'DAVCA - Software'
    }
  },
  {
    path: '/system-audit',
    name: 'system-audit',
    component: lazyLoads('SystemAuditsView'),
    props: true,
    meta: {
      title: 'DAVCA - Audit'
    }
  },
  {
    path: '/biz-digital',
    name: 'biz-digital',
    component: lazyLoads('BizDigitalView'),
    props: true,
    meta: {
      title: 'DAVCA - Digital'
    }
  },
  {
    path: '/plm-customization',
    name: 'plm-customization',
    component: lazyLoads('PLMCustomizationView'),
    props: true,
    meta: {
      title: 'DAVCA - PLM'
    }
  },
  {
    path: '/windchill',
    name: 'windchill',
    component: lazyLoads('WindchillView'),
    props: true,
    meta: {
      title: 'DAVCA - Windchill'
    }
  },
  {
    path: '/parametrics',
    name: 'parametrics',
    component: lazyLoads('ParametricsView'),
    props: true,
    meta: {
      title: 'DAVCA - Parametrics'
    }
  },
  {
    path: '/investors',
    name: 'investors',
    component: lazyLoads('InvestorsView'),
    props: true,
    meta: {
      title: 'DAVCA - Invest'
    }
  },
  {
    path: '/coaching',
    name: 'coaching',
    component: lazyLoads('CoachingView'),
    props: true,
    meta: {
      title: 'DAVCA - Coach'
    }
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: lazyLoads('StrategyView'),
    props: true,
    meta: {
      title: 'DAVCA - Strategy'
    }
  },
  {
    path: '/bi',
    name: 'BI',
    component: lazyLoads('BIView'),
    props: true,
    meta: {
      title: 'DAVCA - BI'
    }
  },
  {
    path: '/missions',
    name: 'missions',
    component: lazyLoads('MissionsView'),
    props: true,
    meta: {
      title: 'DAVCA - Missions'
    }
  },
  {
    path: '/visions',
    name: 'visions',
    component: lazyLoads('VisionsView'),
    props: true,
    meta: {
      title: 'DAVCA - Visions'
    }
  },
  {
    path: '/values',
    name: 'values',
    component: lazyLoads('ValuesView'),
    props: true,
    meta: {
      title: 'DAVCA - Values'
    }
  },
  {
    path: '/business-development',
    name: 'Business Development',
    component: lazyLoads('OrganisationView'),
    props: true,
    meta: {
      title: 'DAVCA - Business Development'
    }
  },
  {
    path: '/financial',
    name: 'financial',
    component: lazyLoads('TaxManagementView'),
    props: true,
    meta: {
      title: 'DAVCA - Financial'
    }
  },
  {
    path: '/business-plan',
    name: 'business-plan',
    component: lazyLoads('BusinessPlanView'),
    props: true,
    meta: {
      title: 'DAVCA - BP'
    }
  },
  {
    path: '/all-services',
    name: 'all-services',
    component: lazyLoads('AllServicesView'),
    props: true,
    meta: {
      title: 'DAVCA - Services'
    }
  },
  // and finally the default route, when none of the above matches:
  { path: '/:pathMatch(.*)*', component: PageNotFoundView.vue },

  {
    path: '/career-detail/:id',
    name: 'career-detail',
    component: lazyLoads('CareerDetailsView'),
    props: true,
    meta: {
      title: 'DAVCA - Career'
    }
  },
]

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

function lazyLoads(view){
  return() => import(`@/views/Pages/${view}.vue`)
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'DAVCA - Home';
  next();
})

export default router
