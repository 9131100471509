import http from "./http-common";

class CareerHttp {
    create(candidat) {
        console.log("formData",candidat);

        return http.post("/candidats", candidat, {
            headers: {
                "Accept": "*/*",
                "Content-Type": "multipart/form-data"
            },
        });
    }

    createNewsletter(newers) {
        console.log("formData",newers);

        return http.post("/newsletter", newers);
    }
}
export default new CareerHttp();