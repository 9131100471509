import { createI18n } from 'vue-i18n'
import en from "./en.json"
import fr from "./fr.json"

const messages = {
    en,
    fr
}

const i18n = createI18n({
    // something vue-i18n options here ...
    locale: localStorage.getItem("lang"),
    messages        

});

export default i18n;