<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">CREO-Parametrics</h2>
                        <h3 class="title" style="text-align: left">
                            With Creo Parametric, and its extensions, you can create, analyze, view, and share designs downstream using 2D CAD, 3D CAD,
                            and parametric and direct modeling capabilities.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="details">
                                <p>
                                    Creo View is a simple but powerful enterprise visualization technology that enables virtually effortless collaboration across local and global design teams. It provides access to multiple forms of engineering data, including 3D CAD models, 2D drawings, electrical schematics, and printed circuit boards. With Creo View, design authors, project managers and downstream suppliers can quickly and easily access and share information between themselves and other stakeholders.
                                </p>
                                <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                    <img src="assets/img/about/chris.png" alt="img">
                                </div>
                                <p>
                                    Creo lets you design smart connected products. So you understand how your products are used and what their performance is. By replacing assumptions with facts, you improve your design decisions.
                                </p>
                                <p>
                                    Now with Creo's model-based definition, engineers integrate directly into the 3D model all the data needed to define the product. The 3D model becomes the source authority that drives all of the company's engineering activities.
                                </p>
                            </div>
                            <h4>More information</h4>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="accordion mt-2" id="accordionExamples">
                                        <div class="accordion-item single-accordion-inner style-2">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    PTC - Windchill
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    PTC - Windchill
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="accordion mt-2" id="accordionExample">
                                        <div class="accordion-item single-accordion-inner style-2">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    CREO Parametrics
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    CREO Parametrics
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/about_davca4.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/abouts_davca1.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'Windchill'
    }
</script>
<style scoped>
    p, ul {
        font-size: 20px;
        text-align: justify;
    }
</style>