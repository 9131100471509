<template>
    <div>
        <Breadcrumb  :pageTitle="$t('message.navbar.MenuItServices.support')" :pageTitles="$t('message.navbar.MenuItServices.support')" />
        <ManagementProject/>
    </div>
</template>

<script>

    import ManagementProject from '@/components/ManagementProject/ManagementProject'
    import Breadcrumb from '@/components/Common/Breadcrumb'
    export default {
        name: 'ManagementProjectView',
        components: {
            ManagementProject,
            Breadcrumb
        }
    }
</script>