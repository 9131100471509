<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-9">
                <div class="section-title text-center">
                    <h2 class="sub-title double-line">PLM Customization</h2>
                    <h2 class="title">Preparing for your success,
                        we provide truly prominent IT solutions
                        On PLM Customization .</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-details-page-content">
                    <div class="single-blog-inner">
                        <div class="details">
                            <h3 style="color: #0060ff">Explore Our PLM Services</h3>
                            <p style="text-align:justify;font-size: 20px">
                                Customization plays an important role in PLM Implementation.
                                To satisfy customer business needs, PLM vendors are trying to build OOTB (Out-of-the-box) solutions based on industry best practices, but “one size doesn’t fit all”. To get the best fit between PLM system and Organizational process, customization is needed. But organizations have to decide on the level of customization needed, its impact on time, cost and future maintenance overheads.
                            </p>
                            <p style="text-align:justify;font-size: 20px">
                                Let’s explore PLM solutions, customization and its impact.
                                And also identify few optimal solutions in implementing successful PLM solutions.
                            </p>
                            <ul style="text-align:justify;font-size: 20px">
                                <li>PLM solutions</li>
                                <li>PLM customization</li>
                                <li>Impact of Customization</li>
                            </ul>
                            <h3 style="color: #0060ff">PLM Solutions</h3>
                            <p style="text-align:justify;font-size: 20px">
                                PLM solutions in last 3-4 decades have changed a lot. Started from Just a
                                PLM Tool kit now to configured and cloud based solution. There are different variants of PLM solution.
                            </p>
                            <ul style="text-align:justify;font-size: 20px">
                                <li>
                                    <strong>PLM Framework: </strong> PLM solutions started by providing PLM
                                    tool kits with a robust PLM framework. The customer was supposed to
                                    utilize teh PLM framework and fully build (customize) PLM solution
                                    to match to their organization business processes.
                                </li>
                                <li><strong>Configurable PLM:</strong> By gaining more insights into industry best practices, PLM vendors started building configurable PLM modules. Most of the current Cloud based PLM falls into this category. In this only few organization specific processes needs to be customized.</li>
                                <li><strong>Pre-Configured:</strong> The idea here is <strong>“one size fits all”</strong>, with a focus on quick PLM deployment. Giving very few options to configure and no customization. PLM vendors think that SMEs will be benefiting form this pre-configured solutions.</li>
                            </ul>
                            <p style="text-align:justify;font-size: 20px">For gaining competitive advantage, there is a need to customize organization specific business process into PLM systems. We suggest choosing right configurable PLM, so organizations can re-use more of the OOTB solution and only customize few company specific processes.</p>
                        </div>
                        <h3 style="color: #0060ff">PLM Services</h3>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">
                                    <div class="details">
                                        <h3>Integrations</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">
                                    <div class="details">
                                        <h3>Implementation</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-right: 5px;">
                                    <div class="details">
                                        <h3>Data Migration</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">
                                    <div class="details">
                                        <h3>ALM Services</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">
                                    <div class="details">
                                        <h3>PLM Testing</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 style="color: #0060ff">More information</h3>
                        <div class="row">
                                <div class="col-md-6">
                                    <div class="accordion mt-2" id="accordionExamples">
                                        <div class="accordion-item single-accordion-inner style-2">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style="font-size: 20px">
                                                    PTC - Windchill
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body" style="font-size: 20px">
                                                    PTC - Windchill
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="accordion mt-2" id="accordionExample">
                                        <div class="accordion-item single-accordion-inner style-2">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button  style="font-size: 20px" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    CREO Parametrics
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body" style="font-size: 20px">
                                                    CREO Parametrics
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumb border-radius-5 mb-4 image-hover-animate">
                            <img src="assets/img/about/about_davca4.png" alt="img">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                            <img src="assets/img/about/abouts_davca1.png" alt="img" style="height: 185px">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="thumb border-radius-5 image-hover-animate">
                            <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'PLMCustomization'
    }
</script>