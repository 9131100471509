<template>
     <div>
        <Breadcrumb pageTitle="Your Career at DAVCA" pageTitles="Career"/>
        <Careers />
    </div>
</template>

<style scoped>
</style>


<script>
    import Breadcrumb from '@/components/Common/Breadcrumb.vue'
    import Careers from '@/components/Careers/Careers.vue'
    export default {
        name: 'CareersView',
        components: {
            Breadcrumb,
            Careers
        }
    }
</script>