<template>
    <div>
        <Breadcrumb pageTitle="Business Development" pageTitles="Business Development" />
        <Organisation/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Organisation from '@/components/Organisation/Organisation'
    export default {
        name: 'OrganisationView',
        components: {
            Breadcrumb,
            Organisation
        }
    }
</script>