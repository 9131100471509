<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h5 class="sub-title double-line">Our visions to Davca</h5>
                        <h2 class="title">build long-term relationships</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="assets/img/banner/7.webp" alt="img">
                            </div>
                            <div class="details">
                                <h2>Our Visions</h2>
                                <p>
                                    It is our deep desire to build long-term relationships with our customers. That's why at Davca, we do not only deliver IT solutions that suit your needs, but we also make sure we establish a real strategy with you. With the market constantly changing as demand grows, we help you simplify the management of your company.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-service-sidebar">
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Catagory</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Machine learning</a></li>
                                <li><a href="#">It management</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                            </ul>
                        </div> 
                        <div class="widget widget_archive">
                            <h4 class="widget-title">Achivment</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Machine learning</a></li>
                                <li><a href="#">It management</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                            </ul>
                        </div>
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Download</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#" download>Download PDF</a></li>
                                <li><a href="#" download>Download DOC</a></li>
                            </ul>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: "Visions"
    }
</script>