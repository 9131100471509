<template>
    <!-- client-area area start -->
    <div class="client-area-area bg-base pt-5 pb-5" style="background-color:#fff">
        <div class="container">
            <div class="client-slider owl-carousel">
                <div class="item">
                    <div class="thumb text-center">
                        <img src="assets/img/client/INNEO-Logo.png" alt="img">
                    </div>
                </div>
                <div class="item">
                    <div class="thumb text-center">
                        <img src="assets/img/client/gedcenters.png" alt="img" style="margin-top: -3rem">
                    </div>
                </div>
                <div class="item">
                    <div class="thumb text-center">
                        <img src="assets/img/client/kuka-logo-png.png" alt="img">
                    </div>
                </div>
                <div class="item">
                    <div class="thumb text-center">
                        <img src="assets/img/client/maverick.png" alt="img" style="margin-top: -5rem">
                    </div>
                </div>
                <div class="item">
                    <div class="thumb text-center">
                        <img src="assets/img/client/Logo_lengs.png" alt="img" style="margin-top: -17px;width: 200px">
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!-- client-area area end -->
</template>
<script>
    export default {
        name: "Customer"
    }
</script>

<style scoped>
    .client-slider .thumb img {
        width: 15rem;
    }
</style>