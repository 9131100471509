<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Our Customers</h2>
                        <h2 class="title">All customers of DAVCA</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="client-area-area pt-5 pb-5">
                        <div class="container">
                            <div class="client-slider owl-carousel">
                                <div class="item">
                                    <div class="thumb text-center">
                                        <img src="assets/img/client/INNEO-Logo.png" alt="img" style="margin-top:30px">
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="thumb text-center">
                                        <img src="assets/img/client/gedcenters.png" alt="img" style="max-width: 12rem;margin-left: -70px;">
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="thumb text-center">
                                        <img src="assets/img/client/kuka-logo-png.png" alt="img" style="margin-left: -130px;margin-top: 30px;">
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="thumb text-center">
                                        <img src="assets/img/client/maverick.png" alt="img" style="margin-left: -130px;margin-top: -3px;height: 200px;width: 200px">
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="thumb text-center">
                                        <img src="assets/img/client/Logo_lengs.png" alt="img" style="margin-left: -130px;margin-top: 15px;width: 200px">
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Customers',
        created() {
           
        },
    }
</script>