<template>
    <!-- skill area start -->
    <div class="skill-area bg-relative pd-top-120 pd-bottom-120">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 pe-5 mb-5 mb-lg-0">
                    <div class="section-title mb-0">
                        <h2 class="sub-title left-border" v-html="$t('message.skills.text1')"> </h2>
                        <h2 class="title" v-html="$t('message.skills.text2')"></h2>
                        <p class="content" style="text-align:justify;font-size: 20px" v-html="$t('message.skills.text3')"></p>
                    </div>
                    <div class="skill-progress-area mt-4">
                        <div class="single-progressbar">
                            <h6 v-html="$t('message.skills.text4')"></h6>
                            <div class="progress-item" id="progress-running">
                                <div class="progress-bg">
                                    <div id="progress" class="progress-rate" data-value="90">
                                    </div>
                                    <div class="progress-count-wrap">
                                        <span class="progress-count counting" data-count="90">0</span>
                                        <span class="counting-icons">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="single-progressbar">
                            <h6 v-html="$t('message.skills.text5')"></h6>
                            <div class="progress-item" id="progress-running-1">
                                <div class="progress-bg">
                                    <div id="progress-1" class="progress-rate" data-value="95">
                                    </div>
                                    <div class="progress-count-wrap">
                                        <span class="progress-count counting" data-count="95">0</span>
                                        <span class="counting-icons">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="single-progressbar">
                            <h6 v-html="$t('message.skills.text6')"></h6>
                            <div class="progress-item mb-0" id="progress-running-3">
                                <div class="progress-bg">
                                    <div id="progress-3" class="progress-rate" data-value="60">
                                    </div>
                                    <div class="progress-count-wrap">
                                        <span class="progress-count counting" data-count="60">0</span>
                                        <span class="counting-icons">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="single-progressbar">
                            <h6 v-html="$t('message.skills.text7')"></h6>
                            <div class="progress-item" id="progress-running-4">
                                <div class="progress-bg">
                                    <div id="progress-4" class="progress-rate" data-value="75">
                                    </div>
                                    <div class="progress-count-wrap">
                                        <span class="progress-count counting" data-count="75">0</span>
                                        <span class="counting-icons">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 px-xl-5">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/about_davca4.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/about_davca30.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- skill area end -->
</template>

<script>
    export default {
        name: 'About'
    }
</script>

<style scoped>
    .mb-0
    {
        margin-bottom: 20px;
    }
</style>