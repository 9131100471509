<template>
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-9">
                        <div class="section-title text-center">
                            <h2 class="sub-title double-line">Careers</h2>
                            <h2 class="title">Become a part of our big family to inspire and get inspired by <span class="text-color-primary"> professional experts.</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="ht-simple-job-listing move-up animate">
                        <div clas="list" v-for="career in info" :key="career.id">
                            <br/>
                            <div class="card" id="carDescJob">

                                <div class="card-header">
                                    <span style="font-size: 1.2rem;font-weight: bold;text-decoration: underline;">Intitulé du poste:</span>
                                    <span style="font-size: 1.5rem;font-weight: bold;color: #0060ff;margin-left:20px">{{career.libelle}}</span>
                                </div>
                                <div class="card-body">
                                    <ul style="font-weight:bold">
                                        <li><i class="bi bi-person-badge-fill"></i>&nbsp;Nombre de candidats:&nbsp;<span style="color:red">{{career.nbre_candidat}}</span></li>
                                        <li class="right-border" style="padding-left: 4px;padding-right: 4px;">&nbsp;</li>
                                        <li><i class="bi bi-geo-alt-fill"></i>&nbsp;Lieu de travail:&nbsp;<span style="color:red">{{career.localisation}}</span> </li>
                                        <li class="right-border" style="padding-left: 4px;padding-right: 4px;">&nbsp;</li>
                                        <li><i class="bi bi-file-earmark-text-fill"></i>&nbsp;Contrat:&nbsp;<span style="color:red">{{career.contrat}}</span></li>
                                        <li class="right-border" style="padding-left: 4px;padding-right: 4px;">&nbsp;</li>
                                        <li><i class="bi bi-hourglass-split"></i>&nbsp;Age requis:&nbsp;<span style="color:red">{{career.ages}}&nbsp;(ans)</span></li>
                                    </ul>
                                    <hr/>
                                    <p class="description" v-html="career.description"></p>


                                    <div class="job-button text-md-center">
                                        <!--<a class="btn btn-primary" v-on:click="getId(`${career.id}`)"  href="/career-detail">Detail</a>-->
                                        <!--                                    <a class="btn btn-primary" v-on:click="getId(`${career.id}`)" href="/career-detail" style="margin-left:50px">Apply</a>-->
                                        <!--                                    <router-link tag="a"-->
                                        <!--                                                 :to="{ name: 'career-detail', params: { id: career.id } }">-->
                                        <!--                                        <button class="btn btn-primary">Apply</button>-->
                                        <!--                                    </router-link>-->
                                        <a :href="$router.resolve({ name: 'career-detail', params: { id: career.id } }).href" class="btn btn-primary">Apply</a>
                                    </div>

                                </div>
                            </div>
                            <!-- <pagination class="pagination" v-model="page" :records="50" :per-page="10" @paginate="myCallback"/> -->
                        </div>
                    </div>
                </div>
                <!--                <div class="col-lg-3 col-12">-->
                <!--                    <div class="td-service-sidebar">-->
                <!--                        <div class="widget widget_catagory">-->
                <!--                            <h4 class="widget-title">Work At DAVCA</h4>                                -->
                <!--                            <ul class="catagory-items">-->
                <!--                                <li><a href="#">Students and Graduates</a></li>-->
                <!--                                <li><a href="#">Experienced Profiles</a></li>-->
                <!--                                <li><a href="#">Training</a></li>-->
                <!--                                <li style="background: var(&#45;&#45;heading-color);color: white;padding-left: 40px;padding-top: 7px;font-weight: bold;"><a href="#">Our Job Offers</a></li>-->
                <!--                                <li><a href="#">Our Areas of Expertise</a></li>-->
                <!--                                <li><a href="#">Speculative Application</a></li>-->
                <!--                            </ul>-->
                <!--                        </div>  -->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import Pagination from 'v-pagination-3';

    export default {
  
    components: {
  Pagination
},
        name: 'Careers',    
        data () {
            return {
                info: {},
                errored: false,
                rows: 100,
                currentPage: 3,
                page: 1
            }
        },
        methods:{

    //         clickCallback (pageNum)  {
    //     console.log(pageNum)
    //   },
            
            getId: function(id){
                localStorage.setItem("careerId",id)  
            },
            

        //     getResults(page = 1) {
        //     axios.get('http://localhost:8000/api/careers?page=' + page)
        //         .then(response => {
        //             this.info = response.data;
        //         });
        // }

        },
        mounted () {
            axios
                //.get('http://localhost:8060/api/careers')
                .get('https://www.davca-cm.com/dashboard/api/careers')
            .then(response => {
               
                this.info = response.data
            })
            .catch(error => {
                this.errored = true
            })
        },

    }
</script>

<style scoped>
    .job-name{
        text-align: center;
    }
    .description{
        
        text-align: left;
        font-size:19px;
        /**Major Properties**/
        overflow:hidden;
        line-height: 2rem;
        max-height: 8rem;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        color: black;
    }
    .card-body ul{
        text-align: center;
        list-style-type: none;
        margin: 0;
        overflow: hidden;
  
    }
    .card-body li{
    
        display: inline-block;
        text-decoration: none;
        float: left;
   }
   .bi{
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        margin:5px;
   }


   a { cursor: pointer; }
    .pagination {
        justify-content: center;
        flex-wrap: wrap;
    }
    .card {
        border: 1px solid rgb(217, 217, 217);
        border-radius: 33px;
        background-color: #ffc107;/*rgb(255, 255, 255);*/
        transition: all 0.2s ease 0s;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
    .card-header {
        background-color: transparent;
    }
    li.right-border:after {
        content: "";
        position: absolute;
        /*right: -12px;*/
        /*top: 1px;*/
        height: 26px;
        width: 3px;
        background: var(--main-color);

    }
</style>

