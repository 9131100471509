<template>
     <!-- project details page start -->
     <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Business Strategy</h2>
                        <h3 class="title" style="text-align: left">
                            Preparing for your success,
                            we provide truly prominent IT solutions
                            On Business Strategy.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb" style="padding: 55px">
                                <img src="capture/decisions.jpg" alt="img">
                            </div>
                            <div class="details">
                                <h2 class="sstitle">Content Approach</h2>
                                <p style="text-align:justify">
                                    In this approach, the field of strategy essentially covers the choices relating to the product-market pairs to which the company decides to devote its efforts. The decisions studied regarding the choices relating to the range of activities. It's about :
                                </p>
                                <ul class="square">
                                    <li><span style="font-weight:bolder">Diversification decisions:</span> Expand the range of goods and services offered by developing new businesses (with new know-how). Involves new competitive markets. Ex: General Electric (financial service, health, turbines (takeover of Alstöm)</li>
                                    <li><span style="font-weight:bolder">Specialization or refocusing decisions:</span> Concentrating efforts or resources on the activities of the most promising professions or those best suited to their skills. Ex: Danone (Food, Health)</li>
                                    <li><span style="font-weight:bolder">Innovation decisions:</span>Schumpeter, modifying production methods. These are the start-ups which, if they work, will be bought out, are in innovation sectors. Ex: Dell new distribution of the product, increase responsiveness to the consumer.</li>
                                    <li><span style="font-weight:bolder">Internationalization decisions:</span>Geographical extension of markets through different methods: export, establishment of a commercial unit, establishment of a production structure. Ex: All companies are affected by internationalization: EDF, Renault</li>
                                </ul>
                                <p style="text-align:justify">
                                    First conception of the strategy is restrictive insofar as it emphasizes the development of the field of activity. What is embarrassing is that it does not take into account the characteristics that condition the internal qualities of the company. However, it neglects the relationship that the company maintains with its environment. These relationships are essential to the definition of strategy. 
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb" style="padding: 55px;margin-top: -75px;">
                                <img src="capture/processus.png" alt="img">
                            </div>
                            <div class="details">
                                <h2 class="sstitle">Process Approach by DAVCA</h2>
                                <p style="text-align:justify">
                                    Strategy can be defined as the set of choices and processes that determines as a whole:
                                </p>
                                <ul class="square">
                                    <li>The internal structures of the company</li>
                                    <li>The relationship it has with its environment. Note: This definition encompasses the previous one by going beyond it because taking the environment into account means taking into account the areas of activity of the company. But these relationships are only part of the links forged with the environment. In particular non-market relations which can impact the strategy.</li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="capture/processus1.png" alt="img">
                            </div>
                            <div class="details">
                                <h2 class="sstitle">Process Approach by DAVCA</h2>
                                <p style="text-align:justify">
                                    The stakeholders, the StakeHolders, represent all the actors in relation to the company and who have an influence on its choices or its performance. This pressure is all the stronger when the company is listed. The company's stakeholders are:

                                </p>
                                <ul class="square">
                                    <li>The state</li>
                                    <li>Governments</li>
                                    <li>Investors</li>
                                    <li>Local Community</li>
                                    <li>Suppliers</li>
                                    <li>Customers</li>
                                    <li>Professional associations (MEDEF, etc.)</li>
                                    <li>Employees</li>
                                    <li>Pressure groups (UFC what to choose?)</li>
                                    <li>Internal organization: This definition also highlights the strategic role played by the organization. The construction of competitive advantage is the mastery of a portfolio of resources and skills.</li>
                                </ul>
                                <p style="text-align:justify">
                                    To establish the link between war strategies and the business world, it is necessary above all to observe the competitive aspect: a company must succeed in imposing itself in its fight against the competition. For this, it will seek all existing solutions and techniques to take advantage of the potential of its environment in order to make a profit. 
                                </p>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'Strategy'
    }
</script>

<style scoped>
    .disc { list-style-type: disc; } 
    .circle { list-style-type: circle; } 
    .square { list-style-type: square; }
    p, ul {
        font-size: 20px;
    }
    .sstitle {
        color: #0060ff;
    }
</style>
