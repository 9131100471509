<template>
     <!-- project details page start -->
     <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">{{$t('message.navbar.MenuItServices.DataMigration')}}</h2>
                        <h2 class="title">
                            Increase the security and efficiency of your own business systems
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="assets/img/about/about_davca30.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/about_davcas.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/about_davca50.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="details">
                        <p>
                            System implementation is a major project that requires time and resources.
                            There is no doubt that it is a process worth devoting attention to and will bring profits in the near future.
                            Software tailored to customer needs is a chance to increase productivity and better results.
                        </p>
                        <h3 style="color: #0060ff">Extract</h3>
                        <p>
                            Obtaining data from external sources. It's always a huge challenge to properly
                            and securely extract the data that we then move to the target location.
                            Extracting is one of the three key elements
                            of data migration that cannot be overlooked in the entire process.
                        </p>
                        <h3 style="color: #0060ff">Transform</h3>
                        <p>
                            Data transformation. This stage may include data transformation from one format to another,
                            data cleaning, filtering or implementation of business rules.
                        </p>
                        <h3 style="color: #0060ff">Load</h3>
                        <p>
                            Loading data into a database (usually a data warehouse),
                            this is generally the last stage of importing final data into the final system.
                        </p>
                        <h3 style="color: #0060ff">We provide</h3>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">

                                    <div class="details row">
                                        <div class="col-lg-2">
                                            <i class="fa fa-clock" style="font-size: 5rem;color: #ffc107;"></i>
                                        </div>
                                        <div class="col-lg-10">
                                            <h3>Time-saving - comprehensive preparation of the migration process</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;height: 7rem">
                                    <div class="details row">
                                        <div class="col-lg-2">
                                            <i class="fa fa-lock" style="font-size: 5rem;color: #ffc107;"></i>
                                        </div>
                                        <div class="col-lg-10">
                                            <h3>Security of migrated data</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">

                                    <div class="details row">
                                        <div class="col-lg-2">
                                            <i class="fa fa-user-check" style="font-size: 5rem;color: #ffc107;"></i>
                                        </div>
                                        <div class="col-lg-10">
                                            <h3>Consulting during the process of data preparation</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">
                                    <div class="details row">
                                        <div class="col-lg-2">
                                            <i class="fa fa-cloud" style="font-size: 5rem;color: #ffc107;"></i>
                                        </div>
                                        <div class="col-lg-10">
                                            <h3>Timeliness - developing and monitoring the migration schedule</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">

                                    <div class="details row">
                                        <div class="col-lg-2">
                                            <i class="fa fa-book" style="font-size: 5rem;color: #ffc107;"></i>
                                        </div>
                                        <div class="col-lg-10">
                                            <h3>Readiness for small and large scale projects</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;height: 7rem">
                                    <div class="details row">
                                        <div class="col-lg-2">
                                            <i class="fa fa-virus" style="font-size: 5rem;color: #ffc107;"></i>
                                        </div>
                                        <div class="col-lg-10">
                                            <h3>Cleaning and harmonization of data</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'DataMigration'
    }
</script>

<style scoped>
    p {
        font-size: 20px;
        text-align: justify;
    }
    .single-service-inner {
        background: cornflowerblue;
    }
</style>