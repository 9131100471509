<template>
    <div>
        <Breadcrumb pageTitle="CAD Computer Design" pageTitles="CAD Computer Design"/>
        <ComputerAided/>
    </div>
</template>

<script>
    import ComputerAided from '@/components/ComputerAided/ComputerAided'
    import Breadcrumb from '@/components/Common/Breadcrumb'
    export default {
        name: 'ComputerAidedView',
        components: {
            ComputerAided,
            Breadcrumb
        }
    }
</script>