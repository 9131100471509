<template>
    <div>
        <div id="main-wrapper">
            <div id="site-wrapper-reveal">
                <Breadcrumb pageTitle="Page Not Found" className="breadcrumb-area"/>
                <PageNotFound/>
                <Footer/>
            </div>
        </div>
    </div>
</template>

<script>
    import PageNotFound from '@/components/PageNotFound/PageNotFound'
    import Footer from '@/components/Layouts/Footer.vue'
    import Breadcrumb from '@/components/Common/Breadcrumb.vue'
    export default {
        name: 'PageNotFoundView',
        components: {
            Breadcrumb,
            Footer,
            PageNotFound
        }
    }
</script>