<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">{{$t('message.navbar.MenuItServices.bic')}}</h2>
                        <h2 class="title">Preparing for your success,
                            we provide truly prominent IT solutions
                            On {{$t('message.navbar.MenuItServices.bic')}} .</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="details">
                                <h2>Business Intelligence, Who is it for ?</h2>
                                <p style="text-align:justify">
                                    Originally, Business Intelligence was the prerogative of a few experts and the services concerned could be counted on the fingers of one hand: accounting, finance, logistics, possibly commerce... In recent years, however, web technologies and the increase the power of computer terminals have facilitated the dissemination of BI tools to users, in discrete forms. Most businesses therefore now use Business Intelligence to consolidate the information they have available. 
                                </p>
                            </div>
                        </div>

                        <div class="single-blog-inner">

                            <div class="details">
                                <h2>The inner workings of BI</h2>
                                <p style="text-align:justify">
                                    The collection of data by the company is the basis on which Business Intelligence will capitalize. After verifying its quality and format, this data is stored in a repository. This repository is generally a centralized computer server, called Datawarehouse (data warehouse) or Datamart (data store for a particular business or type of business). This data can then be distributed to users according to their needs via a tool adapted to the specificities of each business.
                                </p>
                            </div>
                        </div>

                        <div class="single-blog-inner">

                            <div class="details">
                                <h2>Analyze, Restore, Present</h2>
                                <p style="text-align:justify">
                                    The purpose of these different steps for the user is to be able to use the information available. Thus, he is able to improve his ability to make relevant decisions in his activity. BI allows him to analyze data more easily, by restoring it in an accessible and intuitive form. Reports and dashboards* allow the representation and visualization of data in the form of tables and other graphs. The data can become information, which the user will seize and which he will be able to distribute in an optimal way.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-service-sidebar">
                        <div class="widget widget_catagory">
                            <div class="thumb">
                                <img src="capture/bi_image.jpg" alt="img" style="max-width:33rem">
                            </div>
                        </div>
                        <div class="widget widget_archive">
                            <div class="thumb">
                                <img src="capture/bi_image_2.png" alt="img" style="max-width:30rem">
                            </div>
                        </div>
<!--                        <div class="widget widget_catagory">-->
<!--                            <div class="thumb">-->
<!--                                <img src="capture/bi_image_3.jpg" alt="img" style="max-width:30rem">-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'BI'
    }
</script>

<style scoped>
    p {
        font-size: 20px;
    }
</style>