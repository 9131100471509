<template>
    <div>
        <BreadcrumbDouble pageTitleParent="PLM Customization" pageTitle="DAVCA PTC-Windchill" link="/plm-customization" />
        <Windchill/>
    </div>
</template>

<script>
    import Windchill from '@/components/Windchill/Windchill'
    import BreadcrumbDouble from '@/components/Common/BreadcrumbDouble.vue'
    export default {
        name: 'WindchillView',
        components: {
            Windchill,
            BreadcrumbDouble
        }
    }
</script>