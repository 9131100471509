<template>
    <div>
        <Breadcrumb pageTitle="Information Systems Audits"/>
        <SystemeAudits/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import SystemeAudits from '@/components/SystemeAudits/SystemeAudits'
    export default {
        name: 'ManagementProjectView',
        components: {
            SystemeAudits,
            Breadcrumb
        }
    }
</script>