<template>
    <div>
        <Breadcrumb pageTitle="Customer" pageTitles="Customers"/>
        <Customers/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Customers from '@/components/Customers/Customers'
    export default {
        name: 'CustomersView',
        components: {
            Breadcrumb,
            Customers,
        }
    }
</script>