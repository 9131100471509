<template>
    <!--  testimonial area start -->
    <div class="testimonial-area pd-top-90 bg-cover bg-cover-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pe-xl-5 align-self-center">
                    <div class="section-title mb-0">
                        <h2 class="sub-title left-border" v-html="$t('message.testimonial.text1')"></h2>
                        <h2 class="title" v-html="$t('message.testimonial.text2')"></h2>
                    </div>
                    <div class="thumb border-radius-5 mb-4 image-hover-animate">
                        <img src="assets/img/about/dmm_migration.jpg" alt="img">
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!--  testimonial area end -->
</template>
<script>
    export default {
        name: "Testimonial"
    }
</script>