<template>
    <div>
         <Breadcrumb pageTitle="Staff Management and Coaching"/>
         <Coaching/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Footer from '@/components/Layouts/Footer'
    import Coaching from '@/components/Coaching/Coaching'
    export default {
        name: 'CoachingView',
        components: {
            Breadcrumb,
            Coaching
        }
    }
</script>