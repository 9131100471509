<template>
    <div>
        <BreadcrumbDouble pageTitleParent="PLM Customization" pageTitle="DAVCA CREO-Parametrics" link="/plm-customization" />
        <Parametrics/>
    </div>
</template>

<script>
    import Parametrics from '@/components/Parametrics/Parametrics'
    import BreadcrumbDouble from '@/components/Common/BreadcrumbDouble'
    export default {
        name: 'PLMCustomizationView',
        components: {
            BreadcrumbDouble,
            Parametrics
        }
    }
</script>