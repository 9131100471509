<template>
    <!-- about area start -->
    <div class="about-area pd-top-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-9">
                    <div class="about-mask-bg-wrap about-mask-bg-wrap-1 mb-4 mb-lg-0">
                        <img class="shape-image-sm top_image_bounce" src="assets/img/about/1sm.webp" alt="img">
                        <img class="shape-image" src="assets/img/about/1s.webp" alt="img">
                        <div class="thumb">
                            <img src="assets/img/about/team1_davca.png" alt="img">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 align-self-center">
                    <div class="section-title px-lg-5 mb-0">
                        <h2 class="sub-title right-line">About US</h2>
                        <h2 class="title">We execute our ideas form the start to finish.</h2>
                        <h4>
                            Our mission is to accompany our customers as their trusted Global Premium
                            Solutions Integrator and to see their vision become reality together.
                        </h4>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 25px">
                <div class="col-lg-12 align-self-center">
                    <p style="font-size: 20px">
                        Because every single vision of a better world that comes true
                        brings us one step closer to our shared goal.
                    </p>
                    <div class="section-title px-lg-5 mb-0">
                        <h2 class="sub-title right-line">Who We Are</h2>
                        <p style="font-size: 20px">
                            First and foremost, we are experts in digital transformation. But we also see ourselves as enablers, as supporters,
                            as guides. We are a compass calibrated to the world of technology.
                        </p>
                        <p style="font-size: 20px">
                            We rely on our global, international team of over 5 experts. We combine software and
                            consulting expertise in 3 locations worldwide and build on experience from
                            more than 8 projects. We are a one-stop shop: from PLM Data Migration  & Customization,
                            from CAD-Design using PTC CREO Parametrics to CAD Data Cleansing (NX, Solidworks, CREO).
                            We don't bring in one-size-fits-all solutions that have to be tailored to the challenge. We familiarise ourselves with the specific situation – and then develop an individual solution. That’s our promise.
                        </p>
                        <img src="assets/img/about/abouts_first.png" alt="img">
                    </div>
                </div>
            </div>
            <div style="margin-top: 25px" class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-car" style="font-size: 4rem;color:#ffc107;"></i>
                        </div>
                        <div class="details">
                            <h3>Automotive</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-book" style="font-size: 3rem;color:#ffc107;"></i>
                        </div>
                        <div class="details">
                            <h3>Machinery & Heavy Equipment</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-battery-full" style="font-size: 3rem;color:#ffc107;"></i>
                        </div>
                        <div class="details">
                            <h3>Consumer goods</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-burn" style="font-size: 3rem;color:#ffc107;"></i>>
                        </div>
                        <div class="details">
                            <h3>Energy</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: -65px;">
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-cloud" style="font-size: 3rem;color:#ffc107;"></i>
                        </div>
                        <div class="details">
                            <h3>Media & Telecom</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-clinic-medical" style="font-size: 3rem;color:#ffc107;"></i>
                        </div>
                        <div class="details">
                            <h3>Healthcare & Life Sciences</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-server" style="font-size: 3rem;color:#ffc107;"></i>
                        </div>
                        <div class="details">
                            <h3>Software & Technology</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="fa fa-barcode" style="font-size: 3rem;color:#ffc107;"></i>
                        </div>
                        <div class="details">
                            <h3>Commercial & Prof. Services</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- about area end -->

    <!-- why choose area start -->
    <div class="why-choose pd-top-100 pd-bottom-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-9 order-lg-last">
                    <div class="about-mask-bg-wrap about-mask-bg-wrap-2 mb-4 mb-lg-0">
                        <img class="shape-image-sm top_image_bounce" src="assets/img/about/2sm.webp" alt="img">
                        <img class="shape-image" src="assets/img/about/2s.webp" alt="img">
                        <div class="thumb">
                            <img src="assets/img/about/about2_team.png" alt="img">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 order-lg-first align-self-center">
                    <div class="section-title px-lg-5 mb-0">
                        <h2 class="sub-title right-line">Why Choose Us</h2>
                        <h2 class="title">Always available, proactive and listening to the customer.</h2>
                        <p class="content" style="font-size: 20px">To help you remain focus on your core business, by providing you with tools that will help you improve the performance of your business..</p>
                        <div class="choose-wrap mt-4">
                            <div class="media single-choose-inner">
                                <div class="media-left">
                                    <div class="icon">
                                        <i class="icomoon-gear"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h4>Global company</h4>
                                </div>
                            </div>
                            <div class="media single-choose-inner">
                                <div class="media-left">
                                    <div class="icon">
                                        <i class="icomoon-time"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h4>Passionate people</h4>
                                </div>
                            </div>
                            <div class="media single-choose-inner">
                                <div class="media-left">
                                    <div class="icon">
                                        <i class="icomoon-time"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h4>Experience</h4>
                                </div>
                            </div>
                            <div class="media single-choose-inner">
                                <div class="media-left">
                                    <div class="icon">
                                        <i class="icomoon-time"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h4>Culture of partnership</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- why choose area end -->



    <!-- faq area start -->
    <div class="faq-area pd-top-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-8 order-lg-last mb-4 mb-lg-0">
                    <div class="faq-image-wrap">
                        <div class="thumb" style="box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.22);border-radius: 28px;padding: 69px 100px 51px 73px;display: flex;flex-direction: column;padding: 25px;">
                            <img src="assets/img/about/abouts_davca1.png" alt="img">
<!--                            <img class="img-position-1" src="assets/img/about/f2.webp" alt="img">-->
<!--                            <img class="img-position-2 top_image_bounce" src="assets/img/about/f3.webp" alt="img">-->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pe-xl-5 align-self-center">
                    <div class="section-title mb-0">
                        <h2 class="sub-title left-border">Faq</h2>
                        <h2 class="title">Know more about our solutions</h2>
                        <p class="content" style="font-size: 20px">
                            We love answering questions, and we love helping people learn things about technology.
                            In fact, one of the reasons we opened an IT services business is because
                            we think that the main function of technology is
                            to connect people to one another – and we love facilitating that connection.
                        </p>
                    </div>
                    <div class="accordion mt-4" id="accordionExample">
                        <div class="accordion-item single-accordion-inner style-2">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Why the PLM solutions for your service ?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                 data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    A PLM solution accelerates time to market, increases efficiency, reduces product waste and costs,
                                    improves innovation and product design, optimizes collaboration between suppliers
                                    involved in quality initiatives, etc. This business solution aims to network
                                    the various professionals involved and to create a space for collaboration,
                                    exchange, validation, monitoring of changes, etc.
                                    Its use reduces the volume of e-mails, spreadsheets, archive documents of all types
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item single-accordion-inner style-2">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    What is the difference between PLM and ERP ?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                 data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    To simplify, PLM solutions offer a tool for conceptualization and development,
                                    while ERP solutions offer to follow the products once manufactured.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item single-accordion-inner style-2">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    What Is 3d Printing, Rapid Prototyping, And Additive Manufacturing ?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                 data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Ultimately, for the time being, the industry has shifted to Additive Manufacturing (AM) as the all encompassing term and 3D Printing (3DP) as the sub-set for the comparatively inexpensive smaller desktop machines.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- faq area end -->

    <!-- team area start -->
    <div class="team-area pd-top-115 pd-bottom-90">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Expert Team</h2>
                        <h2 class="title">Meet some of our active experts</h2>
<!--                        <p class="content" style="font-size: 20px">Meet some of our active experts</p>-->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-team-inner text-center">
                        <div class="thumb">
                            <img src="assets/img/team/profil_christel.jpg" alt="img">
                        </div>
                        <div class="details-wrap">
                            <div class="details-inner">
                                <h4><a href="#">NGUESSOP TEMFACK Christel</a></h4>
                                <p>Software DevOps</p>
                            </div>
                        </div>
                        <div class="hover-details-wrap">
                            <div class="hover-details-inner">
                                <h4><a href="#">NGUESSOP TEMFACK Christel</a></h4>
                                <p>Software DevOps</p>
                                <ul class="social-media mt-3">
                                    <li>
                                        <a class="facebook" href="#">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="twitter" href="#">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="instagram" href="#">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="youtube" href="#">
                                            <i class="fab fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-team-inner text-center">
                        <div class="thumb">
                            <img src="assets/img/team/Profil_Nadine.jpg" alt="img">
                        </div>
                        <div class="details-wrap">
                            <div class="details-inner">
                                <h4><a href="#">Nadine</a></h4>
                                <p>Expert PLM</p>
                            </div>
                        </div>
                        <div class="hover-details-wrap">
                            <div class="hover-details-inner">
                                <h4><a href="#">Nadine</a></h4>
                                <p>Expert PLM</p>
                                <ul class="social-media mt-3">
                                    <li>
                                        <a class="facebook" href="#">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="twitter" href="#">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="instagram" href="#">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="youtube" href="#">
                                            <i class="fab fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-team-inner text-center">
                        <div class="thumb">
                            <!--img src="assets/img/team/3.webp" alt="img"-->
							<i class="fa fa-user" style="font-size:17rem"></i>
                        </div>
                        <div class="details-wrap">
                            <div class="details-inner">
                                <h4><a href="#">Mme OUAMBO Sabine</a></h4>
                                <p>DIRECTOR/HR</p>
                            </div>
                        </div>
                        <div class="hover-details-wrap">
                            <div class="hover-details-inner">
                                <h4><a href="#">Mme OUAMBO Sabine</a></h4>
                                <p>DIRECTOR/HR</p>
                                <ul class="social-media mt-3">
                                    <li>
                                        <a class="facebook" href="#">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="twitter" href="#">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="instagram" href="#">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="youtube" href="#">
                                            <i class="fab fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- team area end -->
</template>

<script>
    export default {
        name: 'About'
    }
</script>

<style scoped>
    .about-blog {
        padding-top: 10px;
    }
    .single-service-inner {
        background: transparent;
        box-shadow: none;
    }
</style>
