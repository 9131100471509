<template>
    <div class="project-area pd-top-120 mb-4" style="padding-top:50px">
        <div class="container">
            <div class="row">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-9">
                        <div class="section-title text-center">
                            <h2 class="sub-title double-line">Careers Detail ({{careers.libelle}})</h2>
                            <h2 class="title">Become a part of our big family to inspire and get inspired by <span
                                    class="text-color-primary"> professional experts.</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-12">
                    <div class="well">
                        <h3 style="color:red;font-weight:bolder">
                            {{ careers.libelle }}&nbsp;&nbsp;{{ careers.sexe }}
                            <br/>
                            <span style="font-size: 25px;font-style: italic;">
                                {{ date_diff }}
                            </span>
                        </h3>
                        <p style="text-align:justify;font-size: 20px">
                            Do you want to work in a fun atmosphere, with a real team spirit and build skills on large-scale projects? It is
                            possible by joining us!
                        </p>
                        <p style="text-align:justify">
                            <strong>
                                <u>
                                    <h2>Job Description</h2>
                                </u>
                            </strong>
                        </p>
                        <p style="text-align:justify;font-size: 20px" id="block_desc" v-html="careers.description"></p>
                        <p style="text-align:justify">
                            <strong>
                                <u>
                                    <h2>Missions</h2>
                                </u>
                            </strong>
                        </p>
                        <p style="text-align:justify" id="block_mission" v-html="careers.mission"></p>
                        <p style="text-align:justify">
                            <strong>
                                <u>
                                    <h2>Profil</h2>
                                </u>
                            </strong>
                        </p>
                        <p style="text-align:justify" id="block_profile" v-html="careers.profil"></p>
                        <p style="text-align:justify;font-size: 20px">
                            Il s'agit d'un emploi:&nbsp;&nbsp;<span style="color: red;font-weight: bold">{{ careers.contrat }}</span>
                        </p>
                        <p style="text-align:justify;font-size: 20px">
                            Localisation:&nbsp;&nbsp;<span style="color: red;font-weight: bold">{{ careers.localisation }}</span>
                        </p>
                        <p style="text-align:justify;font-size: 20px">
                            You can apply by sending your application file (Cover letter and CV) to <a href="mailto:info@davca.fr" target="_blank">info@davca.fr.</a>
                            Deadline for admissibility is&nbsp;<span style="color: red;font-weight: bold">{{ embauche }}</span>. Please indicate in the subject of the email the title of the position.
                        </p>
                    </div>
                    <hr/>
                    <p style="text-align: justify; margin-bottom: 15px;font-size: 25px;font-weight: bolder;">Fill in the form below <br/><span style="font-size: 17px;font-style: italic;">Required Field</span><span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">(*)</span></p>
                    <div class="contact-page contact-form">
                        <form @submit.prevent="submit">


                            <div class="card">

                                <div class="card-header">
                                    <span style="font-size: 1.2rem;font-weight: bold;">Personal data</span>
                                </div>
                                <div class="card-body">
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <input type="text" name="nom" class="form-control" placeholder="fullName" value="" required>
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <input  required type="email" class="email form-control" name="email" placeholder="Email" value="">
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <input required type="text" name="phone" class="form-control" placeholder="phoneNumber" value="">
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <input required type="text" name="country" class="form-control" placeholder="country" value="">
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <input required type="text" name="region" class="form-control" placeholder="region" value="">
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <input required type="text" name="city" class="form-control" placeholder="city" value="">
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                </div>
                            </div>

                            <div class="card" style="margin-top: 40px">

                                <div class="card-header">
                                    <span style="font-size: 1.2rem;font-weight: bold;">Location</span>
                                </div>
                                <div class="card-body">
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <input required type="text" name="locationwork" class="form-control" placeholder="Set locations of work" value="">
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <textarea required placeholder="Salary expectation (Please enter the gross monthly rate and the currency)" name="textSalary" class="form-control" ></textarea>
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <textarea required placeholder="When are you available to start?" name="textStart" class="form-control" ></textarea>
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <textarea required placeholder="How many years of experience do you have in a similar position?" name="textPosition" class="form-control" ></textarea>
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px">
                                        <textarea style="width: 77.6rem;" placeholder="If you have been recommended by one of our employees, please state their name:" name="textRecommanded" class="form-control" ></textarea>
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-xs-12" style="padding-bottom:20px;display: flex">
                                        <select required class="form-control select2" name="service">
                                            <option selected="selected">Services</option>
                                            <option value="Commercial">Commercial</option>
                                            <option value="Communication">Communication</option>
                                            <option value="Service Technique">Service Technique</option>
                                            <option value="Ressources Humaines">Ressources Humaines</option>
                                        </select>
                                        <span style="color:darkred;font-weight: bolder;font-size: 22px;margin-left: 12px">*</span>
                                    </div>
                                </div>
                            </div>

                            <div class="card" style="margin-top: 40px">

                                <div class="card-header">
                                    <span style="font-size: 1.2rem;font-weight: bold;">File</span>
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <label for="formFileCV" class="form-label">File CV</label>
                                        <input required class="form-control" type="file" id="formFileCV" v-on:change="handleFileUploadCV" ref="file" accept=".pdf">
                                    </div>
                                    <div class="mb-3">
                                        <label for="formFileLM" class="form-label">File LM</label>
                                        <input required class="form-control" type="file" id="formFileLM" v-on:change="handleFileUploadLM" ref="file" accept=".pdf">
                                    </div>
                                </div>
                            </div>


                            <div style="margin-top: 40px">
                                <input type="submit" value="Send your candidature" class="col-md-2 btn btn-primary" />
                            </div>

                        </form>
                    </div>
                </div>
                <!--                <div class="col-lg-3 col-12">-->
                <!--                    <div class="td-service-sidebar">-->
                <!--                        <div class="widget widget_catagory">-->
                <!--                            <h4 class="widget-title">Work At DAVCA</h4>-->
                <!--                            <ul class="catagory-items">-->
                <!--                                <li><a href="#">Students and Graduates</a></li>-->
                <!--                                <li><a href="#">Experienced Profiles</a></li>-->
                <!--                                <li><a href="#">Training</a></li>-->
                <!--                                <li-->
                <!--                                    style="background: var(&#45;&#45;heading-color);color: white;padding-left: 40px;padding-top: 7px;font-weight: bold;">-->
                <!--                                    <a href="#">Our Job Offers</a></li>-->
                <!--                                <li><a href="#">Our Areas of Expertise</a></li>-->
                <!--                                <li><a href="#">Speculative Application</a></li>-->
                <!--                            </ul>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { ref } from 'vue'
    import CareerHttp from "../utility/CareerHttp";
    export default {

        name: 'CareerDetails',
        data() {
            return {
                careers: {},
                date_diff: '',
                embauche: '',
                description: '',
                profil: '',
                mission: '',
                filecv:'',
                filelm:'',
                poste:'',
            }
        },

        mounted() {
            let id = this.$route.params.id;//localStorage.getItem("careerId");

            axios
                .get('http://localhost:8060/api/careers/'+id)
                .then(response => {
                    this.careers = response.data;
                    let debutBase = this.careers.debut_publication;
                    let finBase = this.careers.fin_publication;
                    this.poste = this.careers.libelle;
                    let debut = new Date(debutBase);
                    let fin = new Date(finBase);
                    //
                    let date = new Date();
                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear() + 1;
                    //
                    let currentDate = year + '-' + month + '-' + day;
                    let currentDates = new Date(currentDate);
                    let diff_in_time = fin.getTime() - currentDates.getTime();
                    let diff_in_days = diff_in_time / (1000 * 3600 * 24);
                    if (diff_in_days <= 0) {
                        this.date_diff = "(Deadline exceeded)";
                    }
                    else {
                        this.date_diff = diff_in_days + " left";
                    }

                    const d = new Date(this.careers.date_embauche);
                    this.embauche = d.toDateString();
                });
        },

        methods: {
            handleFileUploadCV(event) {

                if(event.target.files.length == 0) {
                    return;
                }
                let myFile = event.target.files[0];
                this.filecv = myFile;
            },
            handleFileUploadLM(event) {
                if(event.target.files.length == 0) {
                    return;
                }
                let myFile = event.target.files[0];
                this.filelm = myFile;
            },
            submit() {
                //
                let formData = new FormData();
                let fullname = $("input[name=nom]").val();
                let email = $("input[name=email]").val();
                let phone = $("input[name=phone]").val();
                let country = $("input[name=country]").val();
                let region = $("input[name=region]").val();
                let city = $("input[name=city]").val();
                let locationwork = $("input[name=locationwork]").val();
                let textSalary = $('textarea[name="textSalary"]').val();
                let textPosition = $('textarea[name="textPosition"]').val();
                let textStart = $('textarea[name="textStart"]').val();
                let textRecommanded = $('textarea[name="textRecommanded"]').val();
                let service = $('select[name="service"]').val();


                formData.append('filecv', this.filecv);
                formData.append('filelm', this.filelm);
                formData.append('name', fullname);
                formData.append('email', email);
                formData.append('phone', phone);
                formData.append('poste', this.poste);
                formData.append('retenue', '0');
                formData.append('country',country);
                formData.append('region',region);
                formData.append('city',region);
                formData.append('locationwork',locationwork);
                formData.append('salaryexpectation',textSalary);
                formData.append('available',textStart);
                formData.append('recommanded',textRecommanded);
                formData.append('experiences',textPosition);
                formData.append('services',service);



                CareerHttp.create(formData).then((response) => {
                    console.log("Candidat.create", response.data.status);
                    if(response.data.status == 'success') {
                        this.$toast.success(response.data.message);

                        setTimeout(()=>{
                            // location.reload(true);
                            window.location.href="/careers";
                        }, 2000);
                    }
                    else if(response.data.status == 'error') {
                        this.$toast.error(response.data.message)
                    }
                    else if(response.data.status == 'doublon') {
                        this.$toast.warning(response.data.message)
                    }
                }).catch((error) => {
                    //Details about error
                    this.$toast.error(error.response);
                });
            }
        },
    }
</script>

<style scoped>
    h1{
        font-size: 1.2em;
        text-align: center;
        padding: 0.5em;
    }
    .description {
        font-size: 0.8em;
        padding: 0em;
        text-justify: auto;
    }
    blockquote {
        background: #f9f9f9;
        border-left: 1px solid #ccc;
        margin: 1em 10px;
        padding: 2px;
    }
    blockquote p {
        display: inline-block;
    }
    .well {
        background-color: #ececed;
        padding: 10px 20px 35px 20px;
        position: relative;
        width: 100%;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    /* ad for me */



    .customform-control {
        display: block;
        /*width: 395px;*/
        padding-top: 2px;
        padding-left: 10px;
        height: 39px;
        font-size: 14px;
        line-height: 2.42857143;
        color: #71706f;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        border-radius: 0px !important;
    }
    .card-header {
        background-color: transparent;
    }
    .card {
        border: 1px solid rgb(217, 217, 217);
        border-radius: 33px;
        background-color: #ffc107;/*rgb(255, 255, 255);*/
        transition: all 0.2s ease 0s;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
</style>