<template>
    <!-- blog area start -->
    <div class="blog-area pd-top-115 pd-bottom-60">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pe-xl-5 align-self-center">
                    <div class="section-title mb-0">
                        <h2 class="sub-title left-border" v-html="$t('message.blog.text1')"></h2>
                        <h2 class="title" v-html="$t('message.blog.text2')"></h2>
                        <p class="content" style="font-size: 20px;padding-bottom: 25px;" v-html="$t('message.blog.text3')"></p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-inner style-2">
                        <div class="thumb">
                            <img src="assets/img/blog/profil_christel.jpg" alt="img">
                        </div>
                        <div class="details">
                            <h4><a href="#" v-html="$t('message.blog.text4')"></a></h4>
<!--                            <ul class="blog-meta">-->
<!--                                <li><i class="far fa-user"></i> By Admin</li>-->
<!--                                <li><i class="far fa-calendar-alt"></i> 25 June 2025</li>-->
<!--                            </ul>-->
                            <p v-html="$t('message.blog.text7')"></p>
<!--                            <a class="read-more-text" href="#">Read More <i-->
<!--                                    class="fa fa-caret-right"></i></a>-->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-inner style-2">
                        <div class="thumb">
                            <img src="assets/img/blog/Profil_Nadine.jpg" alt="img">
                        </div>
                        <div class="details">
                            <h4><a href="#" v-html="$t('message.blog.text5')"></a></h4>
<!--                            <ul class="blog-meta">-->
<!--                                <li><i class="far fa-user"></i> By Admin</li>-->
<!--                                <li><i class="far fa-calendar-alt"></i> 25 October 2019</li>-->
<!--                            </ul>-->
                            <p v-html="$t('message.blog.text8')"></p>
<!--                            <a class="read-more-text" href="#">Read More <i-->
<!--                                    class="fa fa-caret-right"></i></a>-->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-inner style-2">
                        <div class="thumb">
                            <img src="assets/img/about/about_davca7.png" alt="img" style="height: 26rem">
                        </div>
                        <div class="details">
                            <h4><a href="#" v-html="$t('message.blog.text6')"></a></h4>
<!--                            <ul class="blog-meta">-->
<!--                                <li><i class="far fa-user"></i> By Admin</li>-->
<!--                                <li><i class="far fa-calendar-alt"></i> 25 May 2021</li>-->
<!--                            </ul>-->
                            <p v-html="$t('message.blog.text9')"> </p>
<!--                            <a class="read-more-text" href="#">Read More <i-->
<!--                                    class="fa fa-caret-right"></i></a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- blog area end -->
</template>
<script>
    export default {
        name: "Blog"
    }
</script>