<template>
    <div>
        <!--BreadcrumbData/-->
        <Breadcrumb pageTitle="Our Visions"/>
        <Vision/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Vision from '@/components/Vision/Vision';
    export default {
        name: 'MissionsView',
        components: {
            Vision,
            Breadcrumb
        }
    }
</script>