<template>
        <!-- project details page start -->
        <div class="project-area pd-top-120 mb-4">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-9">
                        <div class="section-title text-center">
                            <h5 class="sub-title double-line">Our missions to Davca</h5>
                            <h2 class="title">To help you remain focus on your core business, by providing you with tools that will help you improve the performance of your business.</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="blog-details-page-content">
                            <div class="single-blog-inner">
                                <div class="thumb">
                                    <img src="assets/img/banner/7.webp" alt="img">
                                </div>
                                <div class="details">
                                    <h2>Our Missions</h2>
                                    <p>
                                        Davca supports you in transforming your business by helping you to optimize your processes and implement the best practices used by leaders in your industry. Through our mission, we want to enable you to react faster and more intuitively to the changing dynamics of the market. We provide you with tools that canfunction anywhere, while staying in line with the expectations of your employees. Because machines and men communicate today more than ever, we give you the ability to create added value from the data. Because we believe that a strong partnership gives you a competitive edge, our team of consultants will help you leverage the right technology so that you can become more agile and competitive in your market.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-12">
                        <div class="td-service-sidebar">
                            <div class="widget widget_catagory">
                                <h4 class="widget-title">Catagory</h4>                                
                                <ul class="catagory-items">
                                    <li><a href="#">Machine learning</a></li>
                                    <li><a href="#">It management</a></li>
                                    <li><a href="#">IT Consultancy</a></li>
                                </ul>
                            </div> 
                            <div class="widget widget_archive">
                                <h4 class="widget-title">Achivment</h4>                                
                                <ul class="catagory-items">
                                    <li><a href="#">Machine learning</a></li>
                                    <li><a href="#">It management</a></li>
                                    <li><a href="#">IT Consultancy</a></li>
                                </ul>
                            </div>
                            <div class="widget widget_catagory">
                                <h4 class="widget-title">Download</h4>                                
                                <ul class="catagory-items">
                                    <li><a href="#" download>Download PDF</a></li>
                                    <li><a href="#" download>Download DOC</a></li>
                                </ul>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- project details page end -->
</template>

<script>
    export default {
        name: "Missions"
    }
</script>