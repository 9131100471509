<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Coaching for Investors in CAMEROON</h2>
                        <h3 class="title" style="text-align: left">Preparing for your success,
                            we provide truly prominent IT solutions
                            On Accompanying Investors in CAMEROON.</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">

                            <div class="details">
                                <h2 class="sstitle">Accompanying Investors in CAMEROON width DAVCA</h2>
                                <p style="text-align:justify">
                                    We support you in the choice of the project to be carried out, the choice of the legal form of your company and the updating of the legal formalities of your company.
                                </p>
                                <p style="text-align:justify">
                                    You want to invest in Cameroon, be your own boss but you don't dare to start? Be accompanied by DAVCA before, during and after the launch of your business. In order to limit the risk of failure, DAVCA provides you with a set of professionals to better supervise you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-service-sidebar">
                        <div class="thumb">
                            <div class="thumb">
                                <img src="assets/img/about/aboutes.png" alt="img">
                            </div>
                        </div>
                        <div class="thumb">
                            <img src="assets/img/about/people-business-presentation.png" alt="img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>





<script>
    export default {
        name: 'Investors'
    }
</script>
<style scoped>
    p, ul {
        font-size: 20px;
        text-align: justify;
    }
    .sstitle {
        color: #0060ff
    }
</style>