<template>
    <!-- how it work area start -->
    <div class="how-it-work-area pd-top-100 pd-bottom-90">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pe-xl-5 align-self-center">
                    <div class="section-title mb-0">
                        <h2 class="sub-title left-border" v-html="$t('message.work.text1')"></h2>
                        <h2 class="title" v-html="$t('message.work.text2')"></h2>
                        <p class="content" style="font-size: 23px;padding-bottom: 60px;" v-html="$t('message.work.text3')">

                        </p>
                    </div>
                </div>
            </div>
            <div class="how-it-work-inner">
                <img class="hills-line" src="assets/img/shape/1.webp" alt="img">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="single-work-inner style-three text-center">
                            <div class="count-inner">
                                <i class="icomoon-project"></i>
                            </div>
                            <div class="details-wrap">
                                <div class="details-inner">
                                    <h2 v-html="$t('message.work.text4')"></h2>
                                    <p v-html="$t('message.work.text5')"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-work-inner style-three text-center">
                            <div class="count-inner">
                                <i class="icomoon-analysis"></i>
                            </div>
                            <div class="details-wrap">
                                <div class="details-inner">
                                    <h2 v-html="$t('message.work.text6')"></h2>
                                    <p v-html="$t('message.work.text7')"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-work-inner style-three text-center">
                            <div class="count-inner">
                                <i class="icomoon-gear"></i>
                            </div>
                            <div class="details-wrap">
                                <div class="details-inner">
                                    <h2 v-html="$t('message.work.text8')"></h2>
                                    <p v-html="$t('message.work.text9')"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-work-inner style-three text-center">
                            <div class="count-inner">
                                <i class="icomoon-approval"></i>
                            </div>
                            <div class="details-wrap">
                                <div class="details-inner">
                                    <h2 v-html="$t('message.work.text10')"></h2>
                                    <p v-html="$t('message.work.text11')"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- how it work area end -->

<!--    <div class="how-it-work-area pd-top-100 pd-bottom-90">-->
<!--        <div class="container">-->
<!--            <div class="row">-->
<!--                <div class="col-lg-12 pe-xl-5 align-self-center">-->
<!--                    <div class="section-title mb-0">-->
<!--                        <h2 class="sub-title left-border">DMM CAD</h2>-->
<!--                        <h2 class="title">DMM : CAD-Migration using DMM (D4)</h2>-->
<!--                    </div>-->
<!--                    <div class="thumb border-radius-5 mb-4 image-hover-animate">-->
<!--                        <img src="assets/img/about/cad_d4.jpg" alt="img">-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>
    export default {
        name: "Work"
    }
</script>
<style scoped>
    .details-inner p{
        text-align: justify-all;
        font-size: 20px;
    }
</style>