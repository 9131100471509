<template>
    <!-- page title start -->
    <div class="breadcrumb-area bg-black bg-relative">
        <div class="banner-bg-img" style="background-image: url('@/assets/img/bg/1.webp');"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-8">
                    <div class="breadcrumb-inner text-center">
                        <h2 class="page-title">{{pageTitle}}</h2>
                        <ul class="page-list">
                            <li><a href="/">Home</a></li>
                            <li>{{pageTitles}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- page title end -->

</template>

<script>
    export default {
        name: 'Breadcrumb',
        props: {
            pageTitle: String,
            pageTitles: String,
        }
    }
</script>