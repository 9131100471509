<template>
    <div>
        <Breadcrumb :pageTitle="$t('message.navbar.MenuItServices.PLMCustomization')" :pageTitles="$t('message.navbar.MenuItServices.PLMCustomization')"/>
        <ITRisks/>
    </div>
</template>

<script>
    import ITRisks from '@/components/ITRisks/ITRisks'
    import Breadcrumb from '@/components/Common/Breadcrumb'
    export default {
        name: 'ItRisksView',
        components: {
            ITRisks,
            Breadcrumb
        }
    }
</script>