<template>
    <!-- banner area start -->
    <div class="banner-area banner-area-3">
        <div class="banner-slider slider-control-round owl-carousel">
            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca5.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1" v-html="$t('message.navbar.MenuItServices.DataMigration')"></h4>
                                <h2 class="title s-animate-2">Increase the security and efficiency <span>of your own business systems</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/dev/data-migration">Get Started</a>
                                    <a class="btn btn-black" href="/dev/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca5.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1" v-html="$t('message.navbar.MenuItServices.PLMCustomization')"></h4>
                                <h2 class="title s-animate-2">PTC-WindChill <span><br/>CREO-Parametrics</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/dev/plm-services">Get Started</a>
                                    <a class="btn btn-black" href="/dev/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca5.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1" v-html="$t('message.navbar.MenuItServices.support')"></h4>
                                <h2 class="title s-animate-2">Because we listen to your needs <span>and advise you on effective solutions</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/dev/software">Get Started</a>
                                    <a class="btn btn-black" href="/dev/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca5.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1" v-html="$t('message.navbar.MenuItServices.bic')"></h4>
                                <h2 class="title s-animate-2">
                                    Create Your custom apps with us <span>and grow up with new technologies in IT!</span>
                                </h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/dev/bi">Get Started</a>
                                    <a class="btn btn-black" href="/dev/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--     For the business service for davca in this section       -->
            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca6.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1" v-html="$t('message.navbar.MenuItServices.bpc')"></h4>
                                <h2 class="title s-animate-2">Business <span><br/>Developement</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/dev/consultant-it">Get Started</a>
                                    <a class="btn btn-black" href="/dev/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca6.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1">Business Plan</h4>
                                <h2 class="title s-animate-2">Business <span><br/>Plan</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/management-it">Get Started</a>
                                    <a class="btn btn-black" href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca6.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1">Financial and Legal</h4>
                                <h2 class="title s-animate-2">Fiancial <span><br/>and Legal</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/management-it">Get Started</a>
                                    <a class="btn btn-black" href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca6.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1">Coaching for Investors in CAMEROON</h4>
                                <h2 class="title s-animate-2">Coaching for <span><br/>Investors in CAMEROON</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/management-it">Get Started</a>
                                    <a class="btn btn-black" href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item bg-overlay" style="height:52rem;background-image: url('assets/img/about/about_davca6.jpg');">
                <div class="banner3-shape shape-1"></div>
                <div class="banner3-shape shape-2"></div>
                <div class="banner3-shape shape-3"></div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="banner-inner style-white text-center">
                                <h4 class="sub-title s-animate-1">Business Strategy</h4>
                                <h2 class="title s-animate-2">Business <span><br/>Strategy</span></h2>
                                <div class="btn-wrap s-animate-4">
                                    <a class="btn btn-base me-2" href="/management-it">Get Started</a>
                                    <a class="btn btn-black" href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- banner area end -->
</template>

<script>
    export default {
        name: "Banner"
    }
</script>