<template>
     <!-- project details page start -->
     <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">{{$t('message.navbar.MenuItServices.support')}}</h2>
                        <h3 class="title" style="text-align: left">
                            Create Your custom apps with us and grow up with new technologies in IT!
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb" style="border-radius: 40px;">
                                <img src="assets/img/about/soft_davca.png" alt="img" style="height: 33rem">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/team1_davca.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/about_davca50.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h3 style="color: #0060ff">
                        Do You need modern software solutions at DAVCA ?
                    </h3>
                    <h4>
                        There we are!
                    </h4>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;">

                            <div class="details">
                                <h3>Modern Web & Desktop Applications + Cloud native apps</h3>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;height: 9.5rem;">
                            <div class="details">
                                <h3>Mobile Applications</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;height: 9.5rem;">
                            <div class="details">
                                <h3>UX/UI Accessibility Design</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;height: 9.5rem;">

                            <div class="details">
                                <h3>Security, Quality & DevOps</h3>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-service-inner text-center" style="padding: 10px;margin-left: 5px;height: 9.5rem;">

                            <div class="details">
                                <h3>Software prototyping</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'ManagementProject'
    }
</script>

<style scoped>
    p, ul {
        font-size: 20px;
        text-align: justify;
    }
</style>
