<template>
    <div>
        <Breadcrumb pageTitle="Business Digitalization"/>
        <BizDigital/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import BizDigital from '@/components/BizDigital/BizDigital'
    export default {
        name: 'BizDigitalView',
        components: {
            BizDigital,
            Breadcrumb
        }
    }
</script>