<template>
    <div>
        <Breadcrumb pageTitle="About Us" pageTitles="Who We Are"/>
        <About/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import About from '@/components/About/About'
    export default {
        name: 'AboutView',
        components: {
            Breadcrumb,
            About
        }
    }
</script>