<template>
    <!-- team area start -->
    <div class="team-area info-box-two pd-top-115 pd-bottom-90">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Contact</h2>
                        <h2 class="title">Get in touch</h2>
                        <p class="content" style="font-size: 20px">We just need a couple of hours!
                            No more than 2 working days since receiving your issue ticket.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-inner text-center">
                        <div class="icon-box">
                            <i class="icomoon-pin"></i>
                        </div>
                        <div class="details-wrap">
                            <div class="details-inner">
                                <h3>Office address</h3>
                                <p style="font-size: 20px">Ngousso Total, BP: 34052, Yaoundé, CAMEROON</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-inner text-center">
                        <div class="icon-box">
                            <i class=" icomoon-email"></i>
                        </div>
                        <div class="details-wrap">
                            <div class="details-inner">
                                <h3>Email Address</h3>
                                <p style="font-size: 20px">info@davca.fr <br> <a href="www.davca-cm.com">www.davca-cm.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-inner text-center">
                        <div class="icon-box">
                            <i class=" icomoon-telephone"></i>
                        </div>
                        <div class="details-wrap">
                            <div class="details-inner">
                                <h3>Phone Number</h3>
                                <p style="font-size: 20px">+237 656 68 71 70<br> +491628520252</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- team area end -->

    <div class="g-map-inner">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3980.674046050662!2d11.531743413857436!3d3.879883399309923!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x108bc5ff8a49d7f9%3A0x85f226d10ea680f9!2sdavca!5e0!3m2!1sfr!2scm!4v1657586901218!5m2!1sfr!2scm" width="580" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border: 0px;"></iframe>
    </div>
    <div class="g-map-contact">
        <div class="row justify-content-end">
            <div class="col-lg-5 col-md-7">
                <form id="consultation">
                    <div class="consulting-contact-form mx-lg-4">
                        <h3 class="mb-3">Free consulting </h3>
                        <div class="single-input-inner style-bg" id="divnom">
                            <input name="nom" type="text" placeholder="Full Name" id="nom">
                        </div>
                        <div class="single-input-inner style-bg" id="divemail">
                            <input name="email" type="text" placeholder="Email Address" id="email">
                        </div>
                        <div class="single-input-inner style-bg" id="divmessage">
                            <textarea name="message" placeholder="Message" id="message" maxlength="4"></textarea>
                        </div>
                        <div class="btn-wrap pb-3">
                            <input type="button" class="btn btn-base" @click="sendContact()" value="Submit Now" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- call to action start -->
    <div class="call-to-action-area pd-top-120 pd-bottom-120 text-center bg-overlay-base"
        style="background-image: url('assets/img/bg/5.webp');">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="single-call-to-action-inner style-white">
                        <h3>We are here to answer your questions 24/7</h3>
                        <h2>Need for IT SOLUTIONS SERVICES </h2>
                        <a href="mailto:services@davca.fr">
                            <span class="btn btn-black mt-3" style="font-size: 20px">
                                Write to this email address (services@davca-cm.com)
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- call to action start -->
</template>

<script>
    export default {
        name: 'Contact',
        methods: {
            sendContact() {
                var info = {};
                var errors = [];
                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                $("#consultation")
                .find("input[type=text]")
                .each(function() {
                    if ($(this).attr("name") == "email" && !pattern.test($(this).val())) {
                        errors.push($(this).attr("name"));
                    }
                    else if ($(this).val() == "" && $(this).attr("name") == "nom") {
                        errors.push($(this).attr("name"));
                    }
                });
                if($("#message").val().length < 5) {
                    errors.push($("#message").attr("name"));
                }
                if (errors.length > 0) {
                    for(var i=0;i<errors.length;i++) {
                        if(errors[i] == "nom") {
                            $("#divnom").css("border", "1px solid #f77575");
                            this.$toast.error("Enter your fullname");
                        }
                        if(errors[i] == "email") {
                            $("#divemail").css("border", "1px solid #f77575");
                            this.$toast.error("Enter your good email address");
                        }
                        if(errors[i] == "message") {
                            $("#divmessage").css("border", "1px solid #f77575");
                            this.$toast.error("Enter your message");
                        }
                    }
                }

                if (errors.length == 0) {
                    alert("teste");
                }
            }
        }
    }
</script>