<template>
     <!-- project details page start -->
     <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Computer Aided Design</h2>
                        <h3 class="title" style="text-align: left">Computer-aided design (CAD) is the use of computers (or workstations) to aid in the creation, modification, analysis, or optimization of a design.</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="assets/img/about/aboute_davcas1.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/about_davca10.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/about_davca50.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h3 style="color: #0060ff">
                        How does computer-aided design work?
                    </h3>
                    <p>
                        A standard CAD system requires the installation of a CAD software package and, occasionally, a graphics card on your computer to work. The graphics kernel is the brains of a CAD software application. The graphical user interface (GUI) is another crucial component of CAD software. The GUI is used to display the CAD geometry and collect user input.
                    </p>
                    <p>
                        Developing computer models with geometrical constraints is known as computer-aided design (CAD). These models often provide a three-dimensional representation of a component or a whole system on a computer screen. Developers can easily modify the model by altering the suitable parameters, which makes life easier for designers and engineers.
                    </p>
                    <p>This indicates that the characteristics and relationships we feed into geometry, shape and size are controlled. If you use solid geometric modeling, which requires that you apply material first, the geometric will respond to forces similarly to real objects.</p>

                    <p>The mouse and keyboards are often used as input devices, and trackballs and digitizers are also occasionally utilized. The GUI transfers the input from the input devices to the graphics kernel in an appropriate format. The graphics kernel creates the geometric entities and instructs the graphics card to show them on the GUI.</p>

                    <p>Design engineers may plan and create their work on a computer screen with CAD, print it, and save it for upcoming revisions. The objects of traditional drafting are represented by CAD software for a mechanical design using either vector-based visuals or, in some cases, raster graphics that show the overall appearance of planned things. It involves more than simply forms, though.</p>

                    <p>According to application-specific norms, the CAD output must express information, such as materials, procedures, measurements, and tolerances, just like hand drafting of technical and engineering drawings. The software also considers the interactions between various materials and stakeholders, which is particularly important as more specifics are added to the drawings by subcontractors.</p>
                    <div class="row">
                        <div class="thumb col-lg-6">
                            <img src="assets/img/about/cad_d4.jpg" alt="img" style="width: 50rem">
                        </div>
                        <div class="thumb col-lg-6">
                            <img src="assets/img/about/dmm45.png" alt="img" style="width: 50rem">
                        </div>
                    </div>
                    <h3 style="color: #0060ff;margin-top: 2rem">
                        CAD-Design using PTC CREO Parametrics
                    </h3>
                    <p>
                        Creo Parametric provides the broadest range of powerful yet flexible CAD 3D modeling software capabilities to accelerate the design of parts and assemblies. With Creo and its extensions, you’ll have access to technologies such as:
                    </p>
                    <ul>
                        <li>Generative design
                        </li>
                        <li>Real-time simulation
                        </li>
                        <li>Additive manufacturing
                        </li>
                        <li>Augmented reality
                        </li>
                    </ul>
                    <p>
                        With Creo Parametric, and its extensions, you can create, analyze, view, and share designs downstream using 2D CAD, 3D CAD, and parametric and direct modeling capabilities.
                    </p>
                    <h3 style="color: #0060ff;margin-top: 2rem">
                        CAD Data Cleansing (NX, Solidworks, CREO)
                    </h3>
                    <div>
                        <div class="thumb">
                            <img src="assets/img/about/cleansing.png" alt="img" style="width: 35rem">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'ComputerAided'
    }
</script>
<style scoped>
    p,ul{
        font-size: 20px;
        text-align: justify;
    }
</style>