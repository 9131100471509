import axios from "axios";

export default axios.create({
	//baseURL: 'http://localhost:8060/api',
	baseURL: 'https://www.davca-cm.com/dashboard/api',
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Content-type": "application/json"
	}
});
