<template>
    <!-- back to top area start -->
    <div class="back-to-top">
        <span class="back-top"><i class="fa fa-angle-up"></i></span>
    </div>
    <!-- back to top area end -->
</template>

<script>
    export default {
        name: 'BackToTop',
        mounted() {
            
        },
    }
</script>