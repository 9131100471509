<template>
    <div>
        <Breadcrumb pageTitle="Business Plan" pageTitles="Business Plan" />
        <BusinessPlan/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import BusinessPlan from '@/components/BusinessPlan/BusinessPlan'
    export default {
        name: 'CoachingView',
        components: {
            Breadcrumb,
            BusinessPlan
        }
    }
</script>