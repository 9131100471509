<template>
    <!-- about area start -->
    <div class="about-area pd-top-90 pd-bottom-120">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="mask-bg-wrap mask-bg-img-3">
                        <!-- <img class="shape-image" src="assets/img/about/2.webp" alt="img"> -->
                        <div class="thumb">
                            <img src="assets/img/about/abouts_first.png" alt="img" style="border-radius:8px">
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 align-self-center">
                    <div class="section-title px-lg-5 mb-0">
                        <h2 class="sub-title left-border" v-html="$t('message.about.text1')"></h2>
                        <h3 class="title" v-html="$t('message.about.text2')"></h3>
                        <p class="content-strong mt-2" style="font-size: 20px;" v-html="$t('message.about.text3')">
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 align-self-center">
                    <div>
                        <ul class="nav nav-tabs tab-button-style mt-4" id="myTab" role="tablist" style="width:40rem">
                            <li class="nav-item" role="presentation" style="font-size: 20px">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                    aria-selected="true" v-html="$t('message.about.text4')"></button>
                            </li>
                            <li class="nav-item" role="presentation" style="font-size: 20px">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                                    type="button" role="tab" aria-controls="profile" aria-selected="false" v-html="$t('message.about.text5')"></button>
                            </li>
                            <li class="nav-item" role="presentation" style="font-size: 20px">
                                <button class="nav-link" id="values-tab" data-bs-toggle="tab" data-bs-target="#values"
                                    type="button" role="tab" aria-controls="values" aria-selected="false" v-html="$t('message.about.text6')"></button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent" style="font-size: 19px;text-align: justify;">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <p class="content mt-4" style="text-align:justify;font-weight: 600;font-size: 21px;" v-html="$t('message.about.text7')">

                                </p>

                                <div class="row" style="margin-top: 50px">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-service-inner text-center" style="height: 20rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details" style="text-align: left">
                                                <p style="font-size: 22px;font-weight: 500;" v-html="$t('message.about.text8')"></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-service-inner text-center" style="height: 20rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details" style="text-align: left">
                                                <p style="font-size: 22px;font-weight: 500;" v-html="$t('message.about.text9')"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <p class="content mt-4" style="text-align:justify;font-weight: 600;font-size: 21px;" v-html="$t('message.about.text10')">

                                </p>
                                <div class="row" style="margin-top: 50px">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-service-inner text-center" style="height: 20rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details" style="text-align: left">
                                                <p style="font-size: 22px;font-weight: 500;" v-html="$t('message.about.text11')"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-service-inner text-center" style="height: 20rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details" style="text-align: left">
                                                <p style="font-size: 22px;font-weight: 500;" v-html="$t('message.about.text12')"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="values" role="tabpanel" aria-labelledby="values-tab">
                                <p>&nbsp;</p>
                                <div class="row" style="margin-top: 50px">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-service-inner text-center" style="height:17rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details">
                                                <h3 v-html="$t('message.about.text13')"></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-service-inner text-center" style="height:17rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details">
                                                <h3 v-html="$t('message.about.text14')"></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-service-inner text-center" style="height:16.5rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details">
                                                <h3 v-html="$t('message.about.text15')"> </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-service-inner text-center" style="height:17rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details">
                                                <h3 v-html="$t('message.about.text16')"></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-service-inner text-center" style="height:17rem;border: 1px solid #d9d9d9;border-radius: 33px;background-color: #fff;transition: all 0.2s;">
                                            <div class="icon-box-bg">
                                                <i class="icomoon-layer"></i>
                                            </div>
                                            <div class="details">
                                                <h3 v-html="$t('message.about.text17')"></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- about area end -->
</template>

<script>
    export default {
        name: 'About'
    }
</script>
