<template>
    <!-- search popup start-->
    <div class="td-search-popup" id="td-search-popup">
        <form action="https://solverwp.com/demo/html/itechie/index.html" class="search-form">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Search.....">
            </div>
            <button type="submit" class="submit-btn"><i class="fa fa-search"></i></button>
        </form>
    </div>
    <!-- search popup end-->
    <div class="body-overlay" id="body-overlay"></div>
</template>

<script>
    export default {
        name: 'Search',
        mounted() {
           
        }
    };
</script>