<template>
     <div>
        <Breadcrumb pageTitle="Blog Post" pageTitles="Blog Post" />
        <Blog />
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb.vue'
    import Blog from '@/components/Blog/Blog.vue'
    export default {
        name: 'BlogView',
        components: {
            Breadcrumb,
            Blog
        }
    }
</script>