<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">Business Development</h2>
                        <h3 class="title" style="text-align: left">
                            Preparing for your success,
                            we provide truly prominent IT solutions
                            On Business Development.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="details">
                                <p>
                                    There is an emerging phenomenon in business, especially in the
                                    innovative technology sector, that can actively be applied to multiple
                                    industries and business models. This phenomenon helps accelerate
                                    revenue growth, especially for companies that may have tapped out
                                    their networks or hit a plateau in sales. Here’s how the new trend
                                    works: Complementary companies — those that operate in the same space
                                    or value chain, but don’t necessarily compete with each other —
                                    are partnering openly and collaboratively. In addition to these
                                    partnerships, companies are strategically positioning themselves
                                    to partner in smart ways with their own customers, too.
                                </p>
                                <h3 style="color: #0060ff;text-align: left">
                                    Setting up a robust business development process will ensure you don’t stagnate and continue to drive revenue.
                                </h3>
                                <p>
                                    A large part of a business development plan includes sales. It also involves:
                                </p>
                                <ul>
                                    <li>marketing</li>
                                    <li>high-level strategic decision making</li>
                                    <li>project management</li>
                                    <li>business planning</li>
                                    <li>product development and management</li>
                                    <li>negotiating and networking</li>
                                    <li>budgeting and initiating cost-saving measures to improve the bottom line</li>
                                </ul>
                                <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                    <img src="assets/img/about/biz_conclu.jpg" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/business_dev1.png" alt="img">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/business_dev2.png" alt="img">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/business_dev3.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->

</template>

<script>
    export default {
        name: 'Organisation'
    }
</script>

<style scoped>
    .disc { list-style-type: disc; } 
    .circle { list-style-type: circle; } 
    .square { list-style-type: square; }
    p, ul {
        font-size: 20px;
    }
</style>
