<template>
    <!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h5 class="sub-title double-line">Business Digitalization</h5>
                        <h2 class="title">Preparing for your success,
                            we provide truly prominent IT solutions
                            On Business Digitalization</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="thumb">
                                <img src="capture/digitalization.webp" alt="img">
                            </div>
                            <div class="details">
                                <h2>Business Digitalization</h2>
                                <p style="text-align:justify">This refers to the process for an organization to fully integrate digital technologies into all its activities. Digitization is much more than setting up new sales and communication channels, it is a transformation of the entire company and its management.

                                    Several approaches can lead to an optimal management and value creation of its data. Some ERP editors or business software will recommend their solutions to support all data, in a new structure, with a technological environment limited to their solutions. One of the strengths of all technological tasks available to businesses is the possibility of synchronization, thanks to APIs. So, digital transformation does not necessarily mean big changes or creative destruction. </p>
                                <p style="text-align:justify">The possibility of communication between the various integrated software and online services allows the creation of “Overlays” or additional modules acting on its installed solutions. About the data and information management, for example, it is possible to create an interface where the files and their data are aggregated, cleaned and sorted in order to make intelligible and accessible quantity of knowledge and information previously dispersed. </p>
                                <h4 class="pt-4 mb-4">Key benefits</h4>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="media single-choose-inner">
                                            <div class="media-left">
                                                <div class="icon">
                                                    <i class="icomoon-gear"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h4>E-invoices</h4>
                                                <p style="text-align:justify">e-invoicing enables the automation of time-consuming and error-prone tasks, such as data entry, reconciliation, and approvals. Staff can thus be reassigned to tasks with higher added value.</p>
                                            </div>
                                        </div>
                                        <div class="media single-choose-inner">
                                            <div class="media-left">
                                                <div class="icon">
                                                    <i class="icomoon-time"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h4>E-procurement</h4>
                                                <p style="text-align:justify">Digitize and automate your purchase-to-pay process with DAVCA E-procurement.</p>
                                            </div>
                                        </div>
                                        <div class="media single-choose-inner">
                                            <div class="media-left">
                                                <div class="icon">
                                                    <i class="icomoon-client"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h4>Supply chain management</h4>
                                                <p style="text-align:justify">Private companies require real-time, quality data to feed to their AI algorithms. This data is the key decision maker in defining what products need manufacturing and which materials need purchasing.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4>More information</h4>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="accordion mt-2" id="accordionExample">
                                            <div class="accordion-item single-accordion-inner style-2">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Identify where there is room for improvement
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        Verification of the rules that should be validated by the transferred data. This verification must be done for both the old rules (compare data models) and the data prior to migration 
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item single-accordion-inner style-2">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Get your organization on board
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        Organizational changes can sometimes feel overwhelming, and understandably so.
                                                        Our advice is to examine one process, understand where there is room for improvement, and start your digitalization journey there.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item single-accordion-inner style-2">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Integrate with your existing systems
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        While embarking on a digital transformation project, make sure not to create more problems than the ones you aim to solve. Do not get trapped in a web of disconnected systems!
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-4 align-self-center mt-4 mt-lg-0">
                                        <div class="thumb image-hover-animate border-radius-5">
                                            <img src="assets/img/service/01.webp" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="td-service-sidebar">
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Catagory</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Machine learning</a></li>
                                <li><a href="#">It management</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                            </ul>
                        </div> 
                        <div class="widget widget_archive">
                            <h4 class="widget-title">Achivment</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#">Machine learning</a></li>
                                <li><a href="#">It management</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                            </ul>
                        </div>
                        <div class="widget widget_catagory">
                            <h4 class="widget-title">Download</h4>                                
                            <ul class="catagory-items">
                                <li><a href="#" download>Download PDF</a></li>
                                <li><a href="#" download>Download DOC</a></li>
                            </ul>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- project details page end -->
</template>

<script>
    export default {
        name: 'BizDigital'
    }
</script>