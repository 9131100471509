<template>
    <div>
        <!--BreadcrumbData/-->
        <Breadcrumb pageTitle="Our Missions"/>
        <Missions/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Missions from '@/components/Missions/Missions';
    export default {
        name: 'MissionsView',
        components: {
            Missions,
            Breadcrumb
        }
    }
</script>