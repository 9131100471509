<template>
<!-- project details page start -->
    <div class="project-area pd-top-120 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-9">
                    <div class="section-title text-center">
                        <h2 class="sub-title double-line">PTC-Windchill</h2>
                        <h3 class="title" style="text-align: left">
                            Improve product quality with new collaboration tools and capabilities and access enhanced deployment options necessary
                            for adapting to today’s changing work environment.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-details-page-content">
                        <div class="single-blog-inner">
                            <div class="details">
                                <h3 style="color: #0060ff">Windchill product lifecycle management software</h3>
                                <p>
                                    Manufacturers have never needed real-time information sharing, dynamic data visualization, and the ability to collaborate more than they do today. With easy, secure data access for multi-disciplinary and geographically-distributed teams, quality-focused processes, and a data driven approach to manufacturing, Windchill is elevating how product development gets done.
                                </p>
                                <p>
                                    Windchill’s open architecture enables easy integration with other enterprise systems, including IoT, providing a solid foundation for a product-driven digital thread. PTC's PLM system provides comprehensive out-of-the-box functionality and highly configurable role and task-based apps. Expand self-service access of traceable product data to non-experts who don’t typically use PLM, while avoiding over-customization and complexity.
                                </p>
                                <h3 style="color: #0060ff">
                                    Enterprise Visualization, Validation, and Review
                                </h3>
                                <p>
                                    Augmented Reality (AR) and 3D visualization provide a new way of engaging with and collaborating around your product definition in PLM. Dynamically build visualization content based on structure and configuration information already in Windchill. Visually interact with product variants filtered by lifecycle state and effectivity.
                                </p>
                                <p>
                                    Windchill offers a robust set of visualization features:
                                </p>
                                <ul>
                                    <li>
                                        Identify differences in model annotation and view state definition to better understand design changes from one version to another
                                    </li>
                                    <li>
                                        Perform robust interference detection
                                    </li>
                                    <li>
                                        Leverage compact viewable data for technical illustrations for delivery to the shop floor or in the field
                                    </li>
                                    <li>
                                        Publish AR experiences through AR design share
                                    </li>
                                </ul>
                            </div>
                            <h4>More information</h4>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="accordion mt-2" id="accordionExamples">
                                            <div class="accordion-item single-accordion-inner style-2">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        PTC - Windchill
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        PTC - Windchill
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="accordion mt-2" id="accordionExample">
                                            <div class="accordion-item single-accordion-inner style-2">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        CREO Parametrics
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        CREO Parametrics
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="thumb border-radius-5 mb-4 image-hover-animate">
                                <img src="assets/img/about/about_davca4.png" alt="img">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate mb-3 mb-md-0">
                                <img src="assets/img/about/abouts_davca1.png" alt="img" style="height: 185px">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="thumb border-radius-5 image-hover-animate">
                                <img src="assets/img/about/about_davca6.png" alt="img" style="height: 11.5rem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- project details page end -->
</template>

<script>
    export default {
        name: 'Windchill'
    }
</script>
<style scoped>
    p, ul {
        font-size: 20px;
        text-align: justify;
    }
</style>