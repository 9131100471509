<template>
    <!-- service area start -->
    <div class="service-area pd-top-115 pd-bottom-90" style="background-image: url('assets/img/bg/6.webp');">
        <div class="container">
            <div class="section-title">
                <div class="row">
                    <div class="col-xl-6 col-lg-7 mb-4 mb-lg-0">
                        <h2 class="sub-title left-border" v-html="$t('message.services.text1')"></h2>
                        <h2 class="title" v-html="$t('message.services.text2')"></h2>
                        <p class="content-strong mt-2" style="font-size: 20px;width: 60rem" v-html="$t('message.services.text3')">

                        </p>
                    </div>
                    <div class="col-xl-6 col-lg-5 align-self-center">
                        <div class="btn-wrap text-md-end">
                            <a class="btn btn-base" href="/dev/all-services" v-html="$t('message.services.text4')"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-layer"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.DataMigration')"></h3>
                            <p v-html="$t('message.services.text6')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.DataMigration')"></h3>
                                <p v-html="$t('message.services.text7')">

                                </p>
                                <a class="btn btn-base btn-small" href="/dev/data-migration" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-application"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.PLMCustomization')"></h3>
                            <p v-html="$t('message.services.text8')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.PLMCustomization')"></h3>
                                <p v-html="$t('message.services.text9')">

                                </p>
                                <a class="btn btn-base btn-small" href="/dev/plm-services" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-cloud-data"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.support')"></h3>
                            <p v-html="$t('message.services.text10')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.support')"></h3>
                                <p v-html="$t('message.services.text11')">

                                </p>
                                <a class="btn btn-base btn-small" href="/dev/software" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-megaphone"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.bic')"></h3>
                            <p v-html="$t('message.services.text12')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.bic')"></h3>
                                <p v-html="$t('message.services.text13')">

                                </p>
                                <a class="btn btn-base btn-small" href="/dev/bi" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-service-inner text-center">
                        <div class="icon-box-bg">
                            <i class="icomoon-lock"></i>
                        </div>
                        <div class="details">
                            <h3 v-html="$t('message.navbar.MenuItServices.bpc')"></h3>
                            <p v-html="$t('message.services.text14')">

                            </p>
                        </div>
                        <div class="details-hover-wrap">
                            <div class="details-hover">
                                <h3 v-html="$t('message.navbar.MenuItServices.bpc')"></h3>
                                <p v-html="$t('message.services.text15')">

                                </p>
                                <a class="btn btn-base btn-small" href="/dev/consultant-it" v-html="$t('message.services.text5')"></a>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="col-lg-4 col-md-6">-->
<!--                    <div class="single-service-inner text-center">-->
<!--                        <div class="icon-box-bg">-->
<!--                            <i class="icomoon-magnifiying-glass"></i>-->
<!--                        </div>-->
<!--                        <div class="details">-->
<!--                            <h3>Software Development Services</h3>-->
<!--                            <p>-->
<!--                                Create Your custom apps with us and grow up with new technologies in IT!-->
<!--                            </p>-->
<!--                        </div>-->
<!--                        <div class="details-hover-wrap">-->
<!--                            <div class="details-hover">-->
<!--                                <h3>Software Development Services</h3>-->
<!--                                <p>-->
<!--                                    Custom software can be used in any type of company to automate activities and increase the company's competitiveness.-->
<!--                                    Take the next big step towards growing your business.-->
<!--                                </p>-->
<!--                                <a class="btn btn-base btn-small" href="/dev/software">View Details</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
    <!-- service area end -->
</template>

<script>
    export default {
        name: 'Services'
    }
</script>

<style scoped>
    .single-service-inner {
        border: 1px solid rgb(217, 217, 217);
        border-radius: 33px;
        background-color: rgb(255, 255, 255);
        transition: all 0.2s ease 0s;
        height: 25rem;
    }
    .details-hover p {
        text-align: left;
    }
</style>