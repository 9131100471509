<template>
    <div>
        <!--BreadcrumbData/-->
        <Breadcrumb pageTitle="Our Values"/>
        <Values/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Values from '@/components/Values/Values';
    export default {
        name: 'ValuesView',
        components: {
            Values,
            Breadcrumb
        }
    }
</script>