<template>
  <div>
    <Banner/>
    <Introduction />
    <About />
    <Services />
    <Skill />
    <Counter />
    <Testimonial />
    <Work />
    <Blog />
    <Faq />
    <Customer />
  </div>
</template>

<script>
  import Banner from '@/components/Home/Banner';
  import Introduction from '@/components/Home/Introduction';
  import About from '@/components/Home/About';
  import Services from '@/components/Home/Services';
  import Skill from '@/components/Home/Skill'
  import Faq from '@/components/Home/Faq'
  import Counter from '@/components/Home/Counter'
  import Testimonial from '@/components/Home/Testimonial';
  import Work from '@/components/Home/Work';
  import Blog from '@/components/Home/Blog';
  import Customer from '@/components/Home/Customer';

  export default {
      name: "HomeView",
      data() {
          return {};
      },
      components: {
        Banner,
        Introduction,
        About,
        Services,
        Skill,
        Faq,
        Counter,
        Testimonial,
        Work,
        Blog,
        Customer
    }
  }
</script>
