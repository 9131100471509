<template>
    <div>
        <Breadcrumb pageTitle="Team DAVCA IT-Services" pageTitles="Team DAVCA IT-Services"/>
        <Erp/>
    </div>
</template>

<script>
    import Erp from '@/components/ERP/Erp'
    import Breadcrumb from '@/components/Common/Breadcrumb'
    export default {
        name: 'ERPView',
        components: {
            Erp,
            Breadcrumb
        }
    }
</script>