<template>
    <div>
        <Breadcrumb  pageTitle="Coaching for Investors in CAMEROON" pageTitles="Coaching for Investors in CAMEROON" />
        <Investors/>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Common/Breadcrumb'
    import Investors from '@/components/Investors/Investors'
    export default {
        name: 'InvestorsView',
        components: {
            Investors,
            Breadcrumb
        }
    }
</script>